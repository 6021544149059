import { gql } from '@apollo/client';
import { AthleteSessionType } from 'models/athlete_session';
import { SessionType } from 'models/team_session';
import { AthleteType } from '../models/athlete';
import { TeamSessionType } from './session';

export type TrackAthleteSessionStateChoices = 'INVALID'|'WAITING'|'PROCESSING'|'ERROR'|'READY'
export type TrackAthleteSessionKpiStateChoices = 'INVALID'|'WAITING'|'PROCESSING'|'INCOMPLETE'|'READY'
export type GenericValueType<T = number | string> = {value?: T, unit?: string, uom?: string}
export type KPIType = {
  name: string,
  value?: number,
  group?: string,
  unit?: string,
  uom?: string,
  isIdentifier?: boolean,
}

export type AthleteSessionsTypeRes = {
  content?: AthleteSessionType[],
  count?: number,
  offset?: number,
  pageSize?: number,
}
export type AthleteSessionsTypeVars = {
  templateId?: string,
  athleteIds?: string[],
  deviceIds?: string[],
  categoryIds?: string[],
  startDate?: string,
  endDate?: string,
  onlyDrills?: Boolean,
  onlySession?: Boolean,
  teamId: string,
  first?: number | null,
  skip?: number | null,
  sort?: string,
  matchDistance?: number[],
  nature?: SessionType[],
  uniqueAthletes?: boolean,

  trackIds?: string[],
}

export type LayoutType = {
  key: string,
  value: {key: string, value: string}[],
  order: string,
}

export const TRACKS = gql`
  query Tracks(
    $teamId: ID!,
    $athleteIds: [ID],
    $deviceIds: [ID],
    $startDate: Date,
    $endDate: Date,
    $exactDate: Date,
    $templateId: ID,
    $first: Int,
    $skip: Int,
    $sort: String
  ) {
    res: tracks(
      teamId: $teamId,
      templateId: $templateId,
      first: $first,
      skip: $skip,
      sort: $sort,
      athleteIds: $athleteIds,
      deviceIds: $deviceIds,
      startDate: $startDate,
      endDate: $endDate,
      exactDate: $exactDate,
    ) {
      content {
          id
          state
          track {
              id
              athlete {
                  id
                  name
              }
              device {
                  serialId
              }
              nSessions
              timestamp
              duration {
                  value
                  unit
                  uom
              }
          }
          ground
      }
      count
      offset
      pageSize
    }
  }
`;

export const TRACKS_KPI = gql`
    query TracksKPI(
        $teamId: ID!,
        $athleteIds: [ID],
        $startDate: Date,
        $endDate: Date,
        $templateId: ID,
        $first: Int,
        $skip: Int,
        $sort: String
    ) {
        res: tracks(
            teamId: $teamId,
            templateId: $templateId,
            first: $first,
            skip: $skip,
            sort: $sort,
            athleteIds: $athleteIds
            startDate: $startDate,
            endDate: $endDate,
        ) {
            content {
                id
                kpi {
                    name
                    value
                    unit
                    uom
                }
            }
            count
            offset
            pageSize
        }
    }
`;

export const BASIC_KPI = (type: 'session' | 'track') => gql`
query BasicKPI(
  $templateId: ID,
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
  $start: Float,
  $end: Float,
  ${type === 'session' ? '$drill: Int' : ''}
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    templateId: $templateId,
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    start: $start,
    end: $end,
    ${type === 'session' ? 'drill: $drill' : ''}
  ) {
    id
    distance{
      value
      uom
      unit
    }
    distWalking{
      value
      uom
      unit
    }
    distRunning{
      value
      uom
      unit
    }  
    timeWalking {
      value
      uom
      unit
    }
    timeRunning {
      value
      uom
      unit
    }
    totalTime {
        value
        uom
        unit
    }
    maxValuesSpeed {
      element { unit, uom, value}
      upperBound { value }
      lowerBound { value }
    }
    maxValuesAcc{
      value
      uom
      unit
    } 
    maxValuesDec{
      value
      uom
      unit
    }
    averageV{
      value
      uom
      unit
    }
    speedEvents{
      eventCount {
        value
        uom
        unit
      }
      thresholdValue {
        value
        uom
        unit
      }  
      eventDuration {
        value
        uom
        unit
      }      
    }
    relativeSpeedEvents{
      eventCount {
        value
        uom
        unit
      }
      thresholdValue {
        value
        uom
        unit
      }  
      eventDuration {
        value
        uom
        unit
      }
    }      
    accelerationEvents {
      eventCount {
        value
        uom
        unit
      }
      thresholdValue {
        value
        uom
        unit
      } 
      eventDuration {
        value
        uom
        unit
      }
    }
    decelerationEvents {
      eventCount {
        value
        uom
        unit
      }
      thresholdValue {
        value
        uom
        unit
      } 
      eventDuration {
        value
        uom
        unit
      }
    }
    tl {
        value
    }   
    rpe{
      element {
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
    }
    imu {
      distanceFront {
            value
            uom
            unit
        }
      distanceRight {
        value
        uom
        unit
      }
      distanceBack{
        value
        uom
        unit
      }
      distanceLeft {
        value
        uom
        unit
      }
    }
  }
}
`;

export const GPEXE_KPI = (type: 'session' | 'track') => gql`
query GPEXEKPI(
  $templateId: ID,
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
  $start: Float,
  $end: Float,
  ${type === 'session' ? '$drill: Int' : ''}
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    templateId: $templateId,
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    start: $start,
    end: $end,
    ${type === 'session' ? 'drill: $drill' : ''}
  ) {
    id
    powerseries {
      id
      x { unit, uom, value}
      y { unit, uom, value}
      group
      props {
        __typename
            ... on GenericValueDict {
              key
              value {
                value
                unit
                uom
              }
            }
            ... on GenericDict {
              key
              genericValue: value
            }
      }
    }
    equivalentDistanceIndex {
      element {
        value
        unit
        uom
      }
      lowerBound {
        value
        unit
        uom
      }
      upperBound {
        value
        unit
        uom
      }
    }
    equivalentDistance {
      value
      uom
      unit
    }
    averageP{
      element {
        value
        unit
        uom
      }
      lowerBound {
        value
        unit
        uom
      }
      upperBound {
        value
        unit
        uom
      }
    }
    mpe{
      value
      unit
      uom
    }
    totalEnergy{
      value
      uom
      unit
    }
    anaerobicEnergy {
      value
      unit
      uom
    }
    maxValuesPower {
      value
      uom
      unit
    }
    powerEventsAvgTime {
      value
      uom
      unit
    }
    powerEventsAvgPower {
      value
      uom
      unit
    }
    recoveryAverageTime {
      value
      uom
      unit
    }
    recoveryAveragePower {
      value
      uom
      unit
    }    
    mpe {
      value
      uom
      unit
    }
  }
}
`;

export const HR_KPI = (type: 'session' | 'track') => gql`
query HRKPI(
  $templateId: ID,
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
  $start: Float,
  $end: Float,
  ${type === 'session' ? '$drill: Int' : ''}
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    templateId: $templateId,
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    start: $start,
    end: $end,
    ${type === 'session' ? 'drill: $drill' : ''}
  ) {
    id
    athlete{
      id
      hrMax{
        value
        uom
        unit
      }
      hrMin{
        value
        uom
        unit
      }
    }
    maxValuesCardio {
      value
      uom
      unit
    }
    averageHr{
      value
      uom
      unit
    }
    averageHrPercentual{
      value
      uom
      unit
    }    
    edwardsTl{
      value
      uom
      unit
    }     
  }
}
`;

export const MECH_KPI = (type: 'session' | 'track') => gql`
    query MechKPI(
        $templateId: ID,
        $${type === 'track' ? 'trackId' : 'id'}: ID!,
        $start: Float,
        $end: Float,
        ${type === 'session' ? '$drill: Int' : ''}
    ) {
        res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
            templateId: $templateId,
            ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
            start: $start,
            end: $end,
            ${type === 'session' ? 'drill: $drill' : ''}
    ) {
    id
    athlete {
        id
        v0 {
            value
            unit
            uom
        }   
    }
    imu {
        divesDetailsBounds
        divesDetails {
          id
          x { unit, uom, value}
          y { unit, uom, value}
          marker
          isLeft
          props {
            __typename
            ... on GenericValueDict {
              key
              value {
                value
                unit
                uom
              }
            }
            ... on GenericDict {
              key
              genericValue: value
            }
          }
          
          group
        }
        eventJumpsCount {
          value 
          uom 
          unit
        }
        eventLeftDivesCount {
          value 
          uom 
          unit
        }
        eventRightDivesCount {
          value 
          uom 
          unit
        }
        eventsDivesByReturnTime {
            id
            props {
                key
                value
            }
            data {
                value
                unit
                uom
            }
        }
        eventsJumps {
            id
            props {
              key
              value
            }
            data {
              value
              unit
              uom
            }
        }
        distanceFront {
            value
            uom
            unit
        }
        distanceRight {
          value
          uom
          unit
        }
        distanceBack{
          value
          uom
          unit
        }
        distanceLeft {
          value
          uom
          unit
        }
        eventsImpacts {
            id
            props {
                key
                value
            }
            data {
                value
                unit
                uom
            }
        }
        eventLeftDives {
            id
            props {
              key
              value
            }
            data {
              value
              unit
              uom
            }
        }
        eventRightDives {
            id
            props {
              key
              value
            }
            data {
              value
              unit
              uom
            }
        }
        eventsDivesLeft {
            id
            props {
              key
              value
            }
            data {
              value
              unit
              uom
            }
        }
        eventsDivesRight {
          id
          props {
            key
            value
          }
          data {
            value
            unit
            uom
          }
        }
    }
    mech {
        activeMuscleLoad {
            value
            unit
            uom
        }
        averageActiveMusclePower {
            value
            unit
            uom
        }
        externalWork {
            value
            unit
            uom
        }
        externalWorkNegative {
            value
            unit
            uom
        }
        averageExternalPower {
            element {
              value
              unit
              uom
            }
            upperBound {
              value
              unit
              uom
            }
            lowerBound {
               value
               unit
               uom
            }
        }
        eccentricIndex {
            element {
                value
                unit
                uom
            }
            upperBound {
                value
                unit
                uom
            }
            lowerBound {
                value
                unit
                uom
            }
        }
       brakeEvents {
            value
            unit
            uom
        }
        burstEvents {
            value
            unit
            uom
        }
        extWorkOver {
            value
            unit
            uom
        }
        extWorkOverNeg {
            value
            unit
            uom
        }
        extWorkOverZone0 {
          value
          unit
          uom
        }
        extWorkOverZone1 {
          value
          unit
          uom
        }
        extWorkOverZone2 {
          value
          unit
          uom
        }
        extWorkOverZone0Neg {
          value
          unit
          uom
        }
        extWorkOverZone1Neg {
          value
          unit
          uom
        }
        extWorkOverZone2Neg {
          value
          unit
          uom
        }
          burstEventsSeries {
            id
            x { value, unit, uom }
            y { value, unit, uom }
            group
            props {
              __typename
            ... on GenericValueDict {
              key
              value {
                value
                unit
                uom
              }
            }
            ... on GenericDict {
              key
              genericValue: value
            }
            }
         }
         brakeEventsSeries {
            id
            x { value, unit, uom }
            y { value, unit, uom }
            group
            props {
              __typename
            ... on GenericValueDict {
              key
              value {
                value
                unit
                uom
              }
            }
            ... on GenericDict {
              key
              genericValue: value
            }
            }
        }
    }       
        }
    }
`;

export const SPEED_ZONES = (type: 'session' | 'track') => gql`
query SpeedZones(
  $templateId: ID,
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
  ${type === 'session' ? '$drill: Int' : ''},
    $start: Float,
    $end: Float,
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    templateId: $templateId,
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    ${type === 'session' ? 'drill: $drill' : ''},
    start: $start,
    end: $end
  ) {
    id
    speedZones{
      time{
        value
        uom 
        unit
      }
      distance{
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
      absLowerBound {
        value
        uom
        unit
      }
      absUpperBound {
        value
        uom
        unit
      }
    }
  }
}
`;

export const REL_SPEED_ZONES = (type: 'session' | 'track') => gql`
    query RelativeSpeedZones(
        $templateId: ID,
        $${type === 'track' ? 'trackId' : 'id'}: ID!,
        ${type === 'session' ? '$drill: Int' : ''},
        $start: Float,
        $end: Float,
    ) {
    res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
      templateId: $templateId,
      ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
      ${type === 'session' ? 'drill: $drill' : ''},
      start: $start,
      end: $end,
    ) {
    id
    relativeSpeedZones{
      time{
        value
        uom
        unit
      }
      distance{
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
      absLowerBound {
        value
        uom
        unit
      }
      absUpperBound {
        value
        uom
        unit
      }
    }
    }
}
`;

export const ACC_ZONES = (type: 'session' | 'track') => gql`
query AccZones(
  $templateId: ID,
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
  ${type === 'session' ? '$drill: Int' : ''}
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    templateId: $templateId,
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    ${type === 'session' ? 'drill: $drill' : ''}
  ) {
    id
    accZones{
      time{
        value
        uom 
        unit
      }
      distance{
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
      absLowerBound {
        value
        uom
        unit
      }
      absUpperBound {
        value
        uom
        unit
      }
    }
  }
}
`;

export const DEC_ZONES = (type: 'session' | 'track') => gql`
query DecZones(
  $templateId: ID,
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
  ${type === 'session' ? '$drill: Int' : ''}
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    templateId: $templateId,
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    ${type === 'session' ? 'drill: $drill' : ''}
  ) {
    id
    decZones{
      time{
        value
        uom 
        unit
      }
      distance{
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
      absLowerBound {
        value
        uom
        unit
      }
      absUpperBound {
        value
        uom
        unit
      }
    }
  }
}
`;

export const POWER_ZONES = gql`
query PowerZones(
  $templateId: ID,
  $id: ID!,
  $drill: Int,
  $start: Float,
  $end: Float,
) {
  res: athletesession(
    templateId: $templateId,
    id: $id,
    drill: $drill,
    start: $start,
    end: $end,
  ) {
      id
		powerZones{
			time{
        value
        uom 
        unit
      }
      distance{
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
      absLowerBound {
        value
        uom
        unit
      }
      absUpperBound {
        value
        uom
        unit
      }
    }
  }
}
`;

export const CARDIO_ZONES = gql`
query CardioZones(
  $templateId: ID,
  $id: ID!
  $drill: Int,  
) {
  res: athletesession(
    templateId: $templateId,
    id: $id,
    drill: $drill
  ) {
      id
		cardioZones{
			time{
        value
        uom 
        unit
      }
      distance{
        value
        uom
        unit
      }
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
      absLowerBound {
        value
        uom
        unit
      }
      absUpperBound {
        value
        uom
        unit
      }
    }
  }
}
`;

export const TRACK_DATA = gql`
query Series(
  $athletesessionId: ID!, 
  $field: String
) {
  res: series(
    athletesessionId: $athletesessionId, 
    field: $field
  ) {
    data
    name
    yAxis {
      name
      lowerBound {
        value
        uom
        unit
      }
      upperBound {
        value
        uom
        unit
      }
    }
  }
}`;

export interface SeriesListRes {
  res: Pick<AthleteSessionType, 'id' | 'seriesList'>
}

export type SeriesListVars = {id: string | undefined} | {trackId: string}

export const SERIES_LIST = (type: 'session' | 'track') => gql`
query SeriesList(
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
  ) {
    id
    seriesList
  }
}
`;

export const EVENTS_LIST = (type: 'session' | 'track') => gql`
query EventsList(
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
  ) {
      id
      eventsList
  }
}
`;

export const SERIES_DATA = (series: string[]) => gql`
query SeriesData(
  $athleteSessionId: ID!,
  $maxSamples: String,
  $drill: Int,  
) {
  ${series.map((serie) => `${serie}: series(
    athletesessionId: $athleteSessionId, 
    field: "${serie}",
    drill: $drill,
    maxSamples: $maxSamples
  ) {
    __typename
    athletesessionId
    id
    lastUpdate
    drill
    data
    timezone
    yAxis {
      lowerBound {
        uom
        unit
      }
    }
  }
  `)}
}
`;

export type EVENTS_TYPES = 'acc'
  | 'anchors_received'
  | 'anchors_used'
  | 'avg_anchors'
  | 'avg_residue'
  | 'bad_fixes_perchdop'
  | 'battery'
  | 'battery_time'
  | 'cardio'
  | 'cardio_percentual'
  | 'cardio_time'
  | 'confidence_level_count'
  | 'course'
  | 'dec'
  | 'direction'
  | 'dist'
  | 'energy'
  | 'equivalent_dist'
  | 'equivalent_slope'
  | 'equivalent_vel'
  | 'event_dives'
  | 'event_impacts'
  | 'event_jumps'
  | 'external_power_negative'
  | 'external_power_positive'
  | 'hdop'
  | 'head_acc'
  | 'heading'
  | 'latitude'
  | 'longitude'
  | 'lps_course'
  | 'mark'
  | 'nsat'
  | 'pdop'
  | 'positioning_time'
  | 'power'
  | 'power_aer'
  | 'quality_time'
  | 'raw_speed'
  | 'reflections_count'
  | 'residue'
  | 'satprsum'
  | 'speed'
  | 'speed_acc'
  | 'speed_time'
  | 'time'
  | 'Vbatt_mV'
  | 'x'
  | 'y'

export const EVENTS_DATA = (series: EVENTS_TYPES[]) => gql`
query EventsData(
  $athletesessionId: ID!,
  $drill: Int,
) { 
  ${series.map((serie) => `${serie}: events(
    athletesessionId: $athletesessionId,
    drill: $drill, 
    type: "${serie}",
  ) {
    id    
    name
    onSeries
    keyOrder
    data {
      x
      color
      fillColor
      group
      title
      genericValues {
        key
        genericValue {
          unit
          value
          uom
        }
      }
      customValues{
        key
        value
      }
    }
  }
  `)}
}
`;

export const SERIES_ZONES_DATA = (
  series: EVENTS_TYPES[],
  type?: 'track' | 'session',
) => gql`
  query SeriesZones(
      $templateId: ID!,
      $id: ID!
  ) {
  ${series.map((serie) => `${serie}: ${
    type === 'track'
      ? 'trackAthletesession'
      : 'athletesession'
  }(
    templateId: $templateId, 
    ${
  type === 'track'
    ? 'trackId'
    : 'id'
}: $id,
  ) {
      id
      ${serie}Zones {
        thresholdIndex
        lowerBound {
          value
          unit
          uom
        }
        upperBound {
          value
          unit
          uom
        }
      }
      maxValuesSpeed {
        upperBound { value }
      }
  }
  `)}
  }
`;

export type PowerEventsVars = {
  templateId: string,
  athletesessionId: string,
  start: number,
  end: number,
}

export type PowerEventsRes = {
  res: {
    powerseries: {
      id: string,
      x: number,
      y: number,
      group: number,
      props: {
        key: string,
        value: {
          value: number,
          unit: string,
          uom: string,
        },
      }[],
    }[],
  },
}

export const POWER_EVENTS = gql`
query PowerEvents( 
    $athletesessionId: ID!,
    $start: Float,
    $end: Float,
    $drill: Int,
) {
  res: athletesession(
      id: $athletesessionId,
      start: $start,
      end: $end,
      drill: $drill,
  ) {
      id
      powerseries {
          id
          x { unit, uom, value}
          y { unit, uom, value}
          group
          props {
              key
              value {
                  value
                  unit
                  uom
              }
          }
      }
  }
}
`;

/**
 * Una tupla rappresentante i dati raccolti:
 * [time, speed, latitude, longitude, heading]
 *
 * @description N.B. il valore della query PATH_DATA è una stringa. Questo tipo è applicabile dopo il parsing
 *
 * @typedef {Array} ViewerDataTuple
 * @property {number} 0 - Time (tempo)
 * @property {number} 1 - Speed (velocità)
 * @property {number} 2 - Latitude (latitudine)
 * @property {number} 3 - Longitude (longitudine)
 * @property {number} 4 - Heading (direzione)
 */
export type ViewerDataTuple = [time: number, speed: number, latitude: number, longitude: number, heading: number, heartRate?: number];

export const PATH_DATA = (type: 'track' | 'session', zipped = false, withViewerData = false, maxSamples = 4000) => gql`
query PathData(
    $${type === 'track' ? 'trackId' : 'id'}: ID!,
    $start: Float,
    $end: Float,
    ${type === 'session' ? '$drill: Int' : ''}
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'},
    start: $start,
    end: $end,
    ${type === 'session' ? 'drill: $drill' : ''}
  ) {
    id
    masterAthleteSession
    path(zipped: ${zipped}) {
      boundingBox{
        coordinates
        area
      }
      data
    }
    powerEventsPath(zipped: ${zipped}){
        boundingBox {
            coordinates
            area
        }        
        data
        colorData
    }
    ${withViewerData ? `viewerData(maxSamples: ${maxSamples})` : ''}
  }
}
`;
export const PATH_DATA_VIEWER_DATA = (maxSamples = 4000) => gql`
query PathDataViewerData(
    $id: ID!,
    $start: Float,
    $end: Float,
) {
  viewerData (
    id: $id,
    start: $start,
    end: $end,
  ) {
    id
    data(maxSamples: ${maxSamples})
  }
}
`;

export interface AthleteDataVars {
  id?: string
  trackId?: string
}

export interface AthleteDataRes {
  res: {
    id: string
    track?: {
      team?: {
        id: string
        name: string
      }
    }
  }
}

export const ATHLETE_DATA = (type: 'track' | 'session') => gql`
query AthleteId(
  $${type === 'track' ? 'trackId' : 'id'}: ID!,
) {
  res: ${type === 'track' ? 'trackAthletesession' : 'athletesession'}(
    ${type === 'track' ? 'trackId' : 'id'}: $${type === 'track' ? 'trackId' : 'id'}
  ) {
    id
    track {
      id
      team {
        id
        name
      }
    }
  }
}
`;

export type JumpsByHeightRes = {
  res: {
    imu: {
      eventsJumps: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
    },
  },
}

export type JumpsByHeightVars = {
  templateId: string,
  trackId: string,
  start: number,
  end: number,
}

export const JUMPS_BY_HEIGHT_DATA = gql`
query JumpsByHeight( 
    $id: ID!, 
    $templateId: ID,
    $start: Float,
    $end: Float,
    $drill: Int,
) {
  res: athletesession(
      id: $id, 
      templateId: $templateId,
      start: $start,
      end: $end,
      drill: $drill,
  ) {
      id
      imu{
          eventsJumps {
              id
              props {
                  key
                  value
              }
              data {
                  value
                  unit
                  uom
              }
          }
      }
  }
}
`;
/** ************************************* */
export type DivesByReturnTimeRes = {
  res: {
    imu: {
      eventsDivesByReturnTime: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
    },
  },
}

export type DivesByReturnTimeVars = {
  templateId: string,
  athletesessionId: string,
  start: number,
  end: number,
}

export const DIVES_BY_RETURN_TIME_DATA = gql`
query DivesByReturnTime( 
    $id: ID!, 
    $templateId: ID,
    $start: Float,
    $end: Float,
    $drill: Int,
) {
  res: athletesession(
      id: $id, 
      templateId: $templateId,
      start: $start,
      end: $end,
      drill: $drill,
  ) {
      id
      imu{
          eventsDivesByReturnTime {
              id
              props {
                  key
                  value
              }
              data {
                  value
                  unit
                  uom
              }
          }
      }
  }
}
`;

export const DIVES_DETAILS = gql`
    query DivesDetails(
        $id: ID!,
        $templateId: ID,
        $start: Float,
        $end: Float,
        $drill: Int,
    ) {
        res: athletesession(
            id: $id,
            templateId: $templateId,
            start: $start,
            end: $end,
            drill: $drill,
        ) {
            id
            imu{
                divesDetailsBounds 
                divesDetails {
                    id
                    x { unit, uom, value}
                    y { unit, uom, value}
                    marker
                    isLeft
                    props {
                        key
                        value {
                            value
                            unit
                            uom
                        }
                    }
                    group
                }
            }
        }
    }
`;

/** ************************************* */
export type DivesByLoadRes = {
  res: {
    id
    imu: {
      eventsDivesLeft: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
      eventsDivesRight: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
    },
  },
}

export type DivesByTotalLoadRes = {
  res: {
    id
    imu: {
      eventLeftDives: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
      eventRightDives: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
    },
  },
}

export type DivesByLoadVars = {
  templateId: string,
  athletesessionId: string,
  start: number,
  end: number,
}

export const DIVES_BY_LOAD_DATA = gql`
query DivesByLoad( 
    $id: ID!, 
    $templateId: ID,
    $start: Float,
    $end: Float,
    $drill: Int,
) {
  res: athletesession(
      id: $id, 
      templateId: $templateId,
      start: $start,
      end: $end,
      drill: $drill,
  ) {
      id
      imu{
          eventsDivesLeft {
              id
              props {
                  key
                  value
              }
              data {
                  value
                  unit
                  uom
              }
          }
          eventsDivesRight {
              id
              props {
                  key
                  value
              }
              data {
                  value
                  unit
                  uom
              }
          }
      }
  }
}
`;

export const DIVES_BY_TOTAL_LOAD_DATA = gql`
    query DivesByTotalLoad(
        $id: ID!,
        $templateId: ID,
        $start: Float,
        $end: Float,
        $drill: Int,
    ) {
        res: athletesession(
            id: $id,
            templateId: $templateId,
            start: $start,
            end: $end,
            drill: $drill,
        ) {
            id
            imu{
                eventLeftDives {
                    id
                    props {
                        key
                        value
                    }
                    data {
                        value
                        unit
                        uom
                    }
                }
                eventRightDives {
                    id
                    props {
                        key
                        value
                    }
                    data {
                        value
                        unit
                        uom
                    }
                }
            }
        }
    }
`;

/** ************************************* */
export type ImpactsByIntensityRes = {
  res: {
    imu: {
      eventsImpacts: {
        id: string,
        props: {
          key: string,
          value: string,
        },
        data: [KPIType],
      }[],
    },
  },
}

export type ImpactsByIntensityVars = {
  templateId: string,
  trackId: string,
  start: number,
  end: number,
}

export const IMPACTS_BY_INTENSITY_DATA = gql`
query ImpactsByIntensity( 
    $id: ID!, 
    $templateId: ID,
    $start: Float,
    $end: Float,
    $drill: Int,
) {
  res: athletesession(
      id: $id, 
      templateId: $templateId,
      start: $start,
      end: $end,
      drill: $drill,
  ) {
      id
      imu {
          eventsImpacts {
              id
              props {
                  key
                  value
              }
              data {
                  value
                  unit
                  uom
              }
          }
      }
  }
}
`;

export type DistanceByBodyOrientationRes = {
  res: {
    imu: {
      distanceFront: KPIType,
      distanceRight: KPIType,
      distanceBack: KPIType,
      distanceLeft: KPIType,
    },
  },
}

export type DistanceByBodyOrientationVars = {
  templateId: string,
  trackId: string,
  start: number,
  end: number,
}

export const DISTANCE_BY_BODY_ORIENTATION = gql`
query DistanceByBodyOrientation( 
    $id: ID!, 
    $templateId: ID,
    $start: Float,
    $end: Float,
    $drill: Int,
) {
    res: athletesession(
        id: $id, 
        templateId: $templateId,
        start: $start,
        end: $end,
        drill: $drill,
    ) {
        id
        imu {
            distanceFront {
                value
                uom
                unit
            }
            distanceRight {
                value
                uom
                unit
            }
            distanceBack{
                value
                uom
                unit
            }
            distanceLeft {
                value
                uom
                unit
            }
        }
    }
}
`;

export const UPDATE_TRACK = (field: string, type?: 'String' | 'ID' | 'ID!' | 'Int') => {
  const query = `mutation UpdateTrack($trackId: ID!, $value: ${type || 'ID!'}${field !== 'groundId' ? ', $groundId: Int' : ''}) {
    updateTrack(trackId: $trackId, ${field}: $value ${field !== 'groundId' ? ', groundId: $groundId' : ''}) {
      id
      ground {
        name
        id
      }
    }
  }`;

  return gql`${query}`;
};

export interface UpdateTrackFullVars {
  trackId: string,
  groundId: number,
  athleteId: string,
  notes: string,
}

export interface UpdateTrackFullRes {
  id: string,
  ground: {
    id: string,
    name: string
  }
  athlete: {
    id: string,
    name: string
  }
  notes
}

export const UPDATE_TRACK_FULL = gql`
    mutation UpdateTrack(
        $trackId: ID!,
        $groundId: Int,
        $athleteId: ID!,
        $notes: String,
    ) {
    updateTrack(
        trackId: $trackId,
        groundId: $groundId,
        athleteId: $athleteId,
        notes: $notes,
    ) {
        id
        ground {
            name
            id
        }
        athlete {
            id
            name
        }
        notes
    }
}`;

export type ReportType = {
  name: string,
  url: string,
  processedDate: Date,
  outcomingId: string,
  storedOnS3: boolean,
}

export type ReportsType = {
  processed: ReportType[],
}

export type StatusType = {
  reports: ReportsType,
}

export type ExportStatusVars = {
  teamId?: string,
  reportsLimit?: number,
}
export type ExportStatusRes = {
  statusQuery: StatusType,
  reportsLimit?: number,
}

export const EXPORT_STATUS = gql`
query ExportStatus($teamId: ID!, $reportsLimit: Int){
  statusQuery(
    teamId: $teamId
    reportsLimit: $reportsLimit
  ){
    reports{
       processed{
         url
         name
         processedDate
         outcomingId
         storedOnS3
       }
    }
  }
}`;

export type GenericDict = {
  [key: string]: any,
}

export type MoreInfoVars = {
  trackId?: string,
  athletesessionId?: string,
}
export type MoreInfoRes = {
  moreInfo: GenericDict[],
}

export const MORE_INFO = gql`
query MoreInfo($trackId: ID, $athletesessionId: ID){
  moreInfo(
    trackId: $trackId,
    athletesessionId: $athletesessionId  
  ){
    key
    value
  }
}`;

export type DeleteTrackVars = {
  trackId?: string,
}
export type DeleteTrackRes = {
  data: {
    deleteTrack: boolean,
  },
}

export type InterpolateSeriesVars = {
  trackId?: string,
  sampleStart?: number,
  sampleEnd?: number,
  field?: string,
}

export const DELETE_TRACK = gql`
mutation DeleteTrackById(
    $trackId: ID!,
) {
    deleteTrack(
        trackId: $trackId,
    )
}`;

export const REMOVE_CARDIO = gql`
    mutation RemoveCardio(
        $trackId: ID!,
    ) {
        removeCardio(
            trackId: $trackId,
        )
    }`;

export const INTERPOLATE_SERIES = gql`
    mutation InterpolateSeries(
        $trackId: ID!,
        $sampleStart: Float!,
        $sampleEnd: Float!,
        $field: String,
    ) {
        interpolateSeries(
            trackId: $trackId,
            sampleStart: $sampleStart,
            sampleEnd: $sampleEnd,
            field: $field
        )
    }`;

export type ReportSeriesVars = {
  teamId?: string,
  excludeFields?: Array<String>,
}

export type Kpi = {
  key: string,
  long_label?: string,
  unit: string,
  group: string,
  label?: string,
  short_label?: string,
  isIdentifier: boolean,
}

export type ReportSeriesRes = {
  reportSeries: Kpi[]
}

export const REPORT_SERIES = gql`
query ReportSeries(
  $teamId: ID!,
  $filterObsolete: Boolean,
  $excludeFields: [String],
) {
  reportSeries(
    teamId: $teamId,
    filterObsolete: $filterObsolete,
    excludeFields: $excludeFields,
  ) {
    key
    unit
    uom
    group
    isIdentifier
  }
}
`;

export const AVAILABLE_ZONES = gql`
    query availableZones($athletesessionId:ID!) {
        res: availableZones(athletesessionId: $athletesessionId)
    }
`;

export interface TracksByDateVars {
  teamId: string,
  exactDate: string,
}

export interface TracksByDateRes {
  res: {
    content: {
      id: string,
      athlete: {
        id: string,
        name: string
      },
      totalTime: GenericValueType
      track: {
        id: string
      }
      startTime: string,
      startDate: string,
      endDate: string
    }[],
    count: number,
    offset: number,
    pageSize: number
  }
}

export const TRACKS_BY_DATE = gql`
    query TracksListByDate(
        $teamId:ID!,
        $startDate: Date,
        $endDate: Date,
        $exactDate: Date,
    ){
        res: tracks(
            teamId: $teamId,
            startDate: $startDate,
            endDate: $endDate,
            exactDate: $exactDate,
        ) {
            content {
                id
                athlete {
                    id
                    name
                }
                totalTime {
                    value
                    unit
                    uom
                }
                track {
                    id
                }
                startTime
                startDate
                endDate
            }
            count
            offset
            pageSize
        }
    }
`;

export interface DaysWithTracksVars {
  endDate?: string,
  startDate?: string,
  teamId: string
}

export interface DaysWithTracksRes {
  res: string[]
}

export const DAYS_WITH_TRACKS = gql`
  query DaysWithTracks(
  $startDate: Date,
  $endDate: Date,
  $teamId:ID!
){
  res: daysWithTracks(
    startDate: $startDate,
    endDate: $endDate,
    teamId:$teamId
  )
}
`;

export type RpeType = {
  id: number,
  athlete: AthleteType,
  teamsession: TeamSessionType,
  format: 'A_1' | 'A_6',
  duration: number,
  value: number,
  starter: boolean,
  picture?: string,
}

export type RpeTypeRes = {
  content?: RpeType[],
  count?: number,
  offset?: number,
  pageSize?: number,
}

export type RpeTypeVars = {
  teamId: string,

  types?: string[],
  first?: number,
  skip?: number,
  sort?: string,
}

export const RPES = gql`
    query Rpes(
        $teamId:ID!,
        $first: Int,
        $skip: Int,
        $sort: String,
        $startDate: Date,
        $matchDistance: [Int],
        $categoryIds: [ID],
        $athleteIds: [ID],
        $types: [RpeSessionType],
        $exactDate: Date,

    ) {
        res: rpes(
            teamId: $teamId,
            first: $first,
            skip: $skip,
            sort: $sort,
            startDate: $startDate,
            matchDistance: $matchDistance,
            categoryIds: $categoryIds,
            athleteIds: $athleteIds,
            types: $types,
            exactDate: $exactDate,
        ) {
            count
            content {
                starter
                athlete {
                    name
                    firstName
                    lastName
                    id
                }
                teamsession {
                    id
                    category {
                        name, color
                    }
                    nature
                    matchDistance {
                        date
                        nextDays
                        nextOpponent
                        nextHome
                        prevDays
                        prevOpponent
                        prevHome                       
                    }
                    drill
                    drillCount
                    startTimestamp
                }
                format
                duration
                value
                id
            }
        }
    }
`;

export const RPE = gql`
    query Rpe(
        $id:ID!,
    ) {
        res: rpe(
            id: $id,
        ) {
            value
            starter
            duration
            format
            athlete {
                firstName
                lastName
                shortName
                name
                id
                thumbnail
                playerSet {
                    number
                    team {
                        id
                    }
                }
            }
            teamsession {
                startTimestamp
                id
                category {
                    name
                    color
                }
                tags {
                    name
                }
                matchDistance {
                    prevDays
                    prevHome
                    prevOpponent
                    nextDays
                    nextHome
                    nextOpponent
                }
                drills {
                    wholeSession {
                        id
                        index
                        totalTime {
                            value
                            unit
                            uom
                        }
                        start
                        end
                    }
                    relatedDrills {
                        id
                        index
                        totalTime {
                            value
                            unit
                            uom
                        }
                        start
                        end
                    }
                }
                matchCycle
            }
        }
    }
`;

export interface LatestTrackVars {
  teamId: string
}

export interface LatestTrackRes {
  res: {
    content: {
      id: string,
      startDate: string
    }[]
    count: number
  }
}

export const LATEST_TRACK = gql`
    query GetLatestTrackDetails(
        $teamId: ID!,
    ) {
        res: tracks(
            teamId: $teamId,
            first: 1,
            sort: "-timestamp",
        ) {
            content {
                id
                startDate
            }
            count
        }
    }
`;

export interface CourseTrackVars {
  athletesessionId: string;
  maxSamples: string;
}

export interface CourseTrackRes {
  id: string;
  data: string;
  lastUpdate: number;
}

export const COURSE_TRACK = gql`
    query GetCourseTrack(
        $athletesessionId: ID!,
        $maxSamples: String,
    ) {
        res: series(
            athletesessionId: $athletesessionId
            field: "heading"
            maxSamples: $maxSamples
        ) {
            id
            data
            drill
            lastUpdate
        }
    } 
`;
