import { GenericValueType } from 'query/track';
import { AthleteSessionType, getAthleteSessionFragment } from './athlete_session';
import { getTeamFragment, TeamType } from './team';
import { DeviceType, getDeviceFragmentName, getDeviceFragment } from './device';

export type AthleteType = {
  // trackSet: TrackType[],
  a0: GenericValueType<number>,
  allTeams?: TeamType[],
  athletechangelogSet: any,
  athletesessionSet: AthleteSessionType[],
  birthdate?: Date, // Format yyyy-mm-dd
  createdOn?: Date,
  customId?: string,
  deviceSet?: DeviceType[],
  firstName: string,
  hrMax: GenericValueType,
  hrMin: GenericValueType,
  id: string,
  isActive?: boolean,
  isDeletable?:boolean,
  jersey?: string,
  lastName: string,
  name?: string,
  picture?: string,
  playerSet: PlayerType[],
  shortName?: string,
  speedMax: GenericValueType,
  teams?: TeamType[],
  thumbnail?: string,
  updatedOn?: Date,
  v0: GenericValueType,
  vo2Max: GenericValueType,
}
export type AthleteResponseType = 'with_club'|'with_team'|'with_athlete_session'|'with_deviceSet'|'no_thumbnail'
// @todo verificare uso di `types` e unicità fragment
export const getAthleteFragmentName = (types: AthleteResponseType[]) => `AthleteTypeFields${types.length > 0 ? '_' + types.join('_') : '' }`;
export const getAthleteFragment = (types: AthleteResponseType[]) => `
    ${types.includes('with_deviceSet') ? getDeviceFragment() : ''}
    ${types.includes('with_team') ? getTeamFragment([]) : ''}
    ${types.includes('with_athlete_session') ? getAthleteSessionFragment([]) : ''}
    fragment ${getAthleteFragmentName(types)} on AthleteType {
      id
      createdOn
      updatedOn
      lastName
      firstName
      name
      shortName
      birthdate
      ${(types.includes('with_club') ? 'clubId' : '')}
      ${(types.includes('no_thumbnail') ? '' : 'thumbnail')}
      playerSet {
        id
        team {
          id
        }
        number
      }
      isDeletable
      hasTracks
      customId${
  types.includes('with_deviceSet') ? `
      deviceSet {
        ...${getDeviceFragmentName()}
      }` : ''}
      isActive
    }
  `

export type PlayerType = {
  id: string,
  createdOn?: number,
  updatedOn?: number,
  team?: TeamType,
  athlete?: AthleteType,
  playingrole?: PlayingRoleType,
  number: string,
  startDate?: Date,
  endDate?: Date,
}
export type PlayingRoleType = {
  id: string,
  createdOn?: number,
  updatedOn?: number,
  name: string,
  value?: string,
  team: TeamType,
  order: number,
  playerSet: PlayerType[],
  isDeletable: boolean;
}
export type PlayingRoleTypeRes = {
  content?: PlayingRoleType[],
  count?: number,
  offset?: number,
  pageSize?: number,
}
