import { GroundType } from '../models/ground';
import { Mark, Slider } from '@material-ui/core';
import { Maybe } from '../__generated__/graphql';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { AthleteSessionType } from '../models/athlete_session';
import { OptionType } from '../components/Autocomplete';
import { ViewerDataTuple } from '../query/track';
import { UserUnitsType } from '../models/me';

export interface PlayersTeams {
  [k: string]: string[]
}

export interface PlayersTeamsContextValue {
  playersTeams: PlayersTeams;
  setPlayersTeams: React.Dispatch<React.SetStateAction<PlayersTeams>>;
}

export const STATUS_TEXT = {
  DATA_SESSION_LOADED: 'data: session loaded, computing...',
  INIT: 'starting...',
  PLAYERS_LOADED: 'players: loaded, computing...',
  PLAYERS_LOADING: 'players: loading...',
  PLAYERS_READY: 'players: ready!',
  SET_DRILLS: 'set: drills',
  SET_START_TIME: 'set: start time',
};

export interface WebPlayerProps {
  currentDrill: number | null;
  defaultGround?: boolean;
  sessionGround?: GroundType
  sessionId: string;
  showVertices?: boolean;
  teamId: string
  templateId: string;
}

export interface SeriesPathDataValue {
  x: number | null;
  y: number | null;
  x_0?: number | null;
  y_0?: number | null;
  s?: number | null;
  h?: number | null;
  hr?: number | null;
}

export interface SeriesPathData {
  [t: number]: SeriesPathDataValue
}

export interface SeriesState {
  [k: string]: {
    pathData: SeriesPathData;
  };
}

export interface PlayersExtraData {[k: string]: {s: number | null, hr: number | null}}

export type DrillTimesType = { start: number; end: number; }[]
export type PlayersColors = Record<string, string>
export type PlayerDataInterval = [number, number];
export type PlayerDataIntervals = PlayerDataInterval[];

export type pathType = {
  timestamp: number,
  x: number | null,
  y: number | null,
  s: number | null,
  h: number | null,
  hr?: number | null,
}[];

export type MarkerProps = {
  active: boolean;
  color?: string;
  cursor: number;
  extraData?: SeriesPathDataValue | null
  hover: boolean;
  id: string;
  onClick: ( data: any ) => void;
  onDblClick?: ( data: any ) => void;
  onMouseEnter?: ( data: any ) => void;
  onMouseLeave?: ( data: any ) => void;
  pathData: pathType;
  scale: number;
  showExtraData: boolean;
  text: string;
  textColor?: string;
  userUnits?: UserUnitsType;
};

export interface MarkDrill extends Mark {
  index?: Maybe<number>;
  id?: Maybe<number>;
  tags?: Maybe<string>[];
  idx?: number;
}

export interface PlayerControlsProps {
  currDrillRef: React.MutableRefObject<number>;
  currentDrill: number | null;
  currentTime: number;
  drillTimes: DrillTimesType;
  enabled: boolean;
  endTimestamp?: string;
  goToNextDrill: () => void;
  goToPrevDrill: () => void;
  handleChange: (event: ChangeEvent<{}> | null, time: number) => void;
  handleFullscreenClick: () => void;
  handlePlayClick: () => void;
  intervals: PlayerDataIntervals;
  marks: MarkDrill[];
  playerSpeed: number;
  playerStatus: 0 | 1;
  setPlayerSpeed: (n: number) => void;
  startTimestamp?: string;
  timeFormat: string;
  timestamps: number[];
  valueLabelFormat: React.ComponentProps<typeof Slider>['valueLabelFormat'];
}

export interface SliderDrillsProps {
  marks: MarkDrill[];
  min?: number;
  max?: number;
  currentTime: number;
  timestamps: number[];
}

export interface DrawerPlayerDetailsProps {
  currentPlayer: null | number;
  playersDetails: PlayersDetails;
  status: string;
  setCurrentPlayer: Dispatch<SetStateAction<null | number>>;
  setPlayersColors: Dispatch<SetStateAction<Record<string, string>>>;
}

export interface GroundProps {
  currentActivePlayer: string | null;
  hoverPlayer: string | null;
  defaultPlayersColor: string;
  enabledPlayers: Array<string>;
  ground: GroundType;
  jerseyOrNumber: boolean;
  handlePlayerClick: (playerID: string) => void;
  handlePlayerEnter?: (playerID: string) => void;
  handlePlayerLeave?: (playerID: string) => void;
  infoPlayers: InfoPlayers;
  isLoadingData?: boolean;
  playerLinks: Set<[string, string]>;
  playersColors: PlayersColors;
  playersDetails: PlayersDetails;
  handleRemoveLink: (idx: number) => void;
  series: SeriesState;
  showVertices?: boolean;
  trailsEnabled: boolean;
  userUnits?: UserUnitsType;
}

export const timestampSearchRange = 200; // ms, max cursor proximity range
export const markerTrailLength = 3000; // ms, trail length

export type PlayerLines = {
  start: [number, number],
  end: [number, number],
  distance: number,
}[]

export interface GroundFieldProps {
  activePlayer?: string | null
  hoverPlayer?: string | null
  children?: React.ReactNode;
  cursor: any;
  defaultPlayersColor?: string;
  enabledPlayers?: Array<string>;
  infoPlayers: InfoPlayers;
  jerseyOrNumber?: boolean;
  onPlayerClick?: (playerID: string) => void;
  onPlayerDblClick?: (playerID: string) => void;
  onPlayerEnter?: (playerID: string) => void;
  onPlayerLeave?: (playerID: string) => void;
  playersColors?: PlayersColors;
  playersDetails?: PlayersDetails;
  playersLines?: PlayerLines;
  removeLink?: (idx: number) => void;
  series?: SeriesState;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  showVertices?: boolean;
  trailsEnabled?: boolean;
  userUnits?: UserUnitsType;
}

export interface GroundFieldData {
  dataGround?: GroundType;
  offX: number;
  offY: number;
  scale: number;
  WINDOW_WIDTH: number;
  WINDOW_HEIGHT: number;
  zoom: number;
}

export interface PlayersLinkI {
  distance: number
  end: [number, number],
  start: [number, number]
}

export interface PlayersLinkProps {
  playersLines: PlayersLinkI[];
  removeLink: (id: number) => void;
  groundSurfaceColor?: string;
}

export interface PlayersMarkersProps {
  activePlayer: string | null;
  cursor: number;
  defaultPlayersColor?: string;
  enabledPlayers: Array<string>;
  groundSurfaceColor?: string;
  hoverPlayer: string | null;
  infoPlayers: InfoPlayers;
  jerseyOrNumber: boolean;
  onPlayerClick: (playerID: string) => void;
  onPlayerDblClick?: (playerID: string) => void;
  onPlayerEnter?: (playerID: string) => void;
  onPlayerLeave?: (playerID: string) => void;
  playersColors: PlayersColors;
  playersDetails: PlayersDetails;
  scale: number;
  series: SeriesState;
  trailsEnabled: boolean;
  userUnits?: UserUnitsType;
  playersTeams: PlayersTeams;
}

export interface PlayerDetails {
  id: number;
  name: string;
  number: number | null;
  shortName: string;
}

type StatusKeys = keyof typeof STATUS_TEXT;
type Status = typeof STATUS_TEXT[StatusKeys];

export interface PlayerDetails {
  id: number;
  name: string;
  number: number | null;
  shortName: string;
}

export type PlayersDetails = Record<string, PlayerDetails>;

export interface PlayersTableProps {
  athleteSessions: AthleteSessionType[];
  enabledPlayers: Array<string>;
  groundSurfaceColor: string;
  infoPlayers: InfoPlayers;
  jerseyOrNumber: boolean;
  loadingPlayers: Array<string>;
  playersColors: Record<string, string>;
  playersDetails: PlayersDetails;
  playersExtraData: PlayersExtraData;
  setInfoPlayers: React.Dispatch<React.SetStateAction<InfoPlayers>>;
  setEnabledPlayers: React.Dispatch<React.SetStateAction<Array<string>>>
  setPlayersColors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  status: Status;
}

export interface WebPlayerHeaderProps {
  ground?: GroundType;
  groundOptions: OptionType[];
  groundSet: GroundType[];
  isSessionDataReady: boolean;
  jerseyOrNumber: boolean;
  setGround: React.Dispatch<React.SetStateAction<GroundType | undefined>>;
  setJerseyOrNumber: React.Dispatch<React.SetStateAction<boolean>>;
  setTrailsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  trailsEnabled: boolean;
}

export type PathType = {
  [k: string] :{
    x: number | null,
    x_0: number | null,
    y: number | null,
    y_0: number | null,
    s: number | null,
    h: number | null,
  }
};

export type PathProps = { pathData: PathType; cursor: number; scale?: number, pathColor?: string; };

export interface GPSGroundProps {
  activePlayer: string | null;
  hoverPlayer: string | null;
  defaultPlayersColor?: string;
  enabledPlayers: Array<string>;
  groundId: string;
  infoPlayers: InfoPlayers;
  jerseyOrNumber: boolean;
  onPlayerClick: (playerID: string) => void;
  onPlayerEnter?: (playerID: string) => void;
  onPlayerLeave?: (playerID: string) => void;
  playerLinks: Set<[string, string]>;
  playersColors: PlayersColors;
  playersDetails: PlayersDetails;
  removeLink: (idx: number) => void;
  series: SeriesState;
  showGroundName?: boolean;
  showVertices?: boolean;
  trailsEnabled: boolean;
  userUnits?: UserUnitsType;
}

export interface LPSGroundProps {
  activePlayer: string | null;
  hoverPlayer: string | null;
  defaultPlayersColor?: string;
  enabledPlayers: Array<string>;
  groundId: string;
  infoPlayers: InfoPlayers;
  jerseyOrNumber: boolean;
  onPlayerClick: (playerID: string) => void;
  onPlayerEnter?: (playerID: string) => void;
  onPlayerLeave?: (playerID: string) => void;
  playerLinks: Set<[string, string]>;
  playersColors: PlayersColors;
  playersDetails: PlayersDetails;
  removeLink: (idx: number) => void;
  series: SeriesState;
  showGroundName?: boolean;
  showVertices?: boolean;
  trailsEnabled: boolean;
  userUnits?: UserUnitsType;
}

export interface ZoomControlsProps {
  setZoom: React.Dispatch<React.SetStateAction<number>>
  zoom: number;
}

export interface WebplayerWorkerNew extends Omit<Worker, 'postMessage'> {
  postMessage(command: WebPlayerWorkerProps): void;
}

export interface WebPlayerWorkerProps {
  action: 'loadPathData' | 'loadMultiplePathData' | 'abort' | 'requireInit';
  drill?: null | number;
  jwtToken?: string;
  maxSamples?: number;
  athleteSessionId?: string;
  athleteSessionIds?: string[];
  start?: number;
  end?: number;
/*  computeData?: {
    currentGround?: GroundType;
    ground?: GroundType;
    groundDetails: false | GroundType | undefined;
    defaultGround: boolean | undefined;
  }*/
}

export interface WebPlayerWorkerResponse {
  type: 'loadedPathData' | 'ready',
  serie: {
    id: string,
    drill: number | null,
    data: ViewerDataTuple[],
    intervals: PlayerDataIntervals,
    // computed: {[k: number]: SeriesPathDataValue}
  }
}

export type InfoPlayers = Array<string>;
