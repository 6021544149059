import React, {
  useRef, useEffect, useMemo, useState,
} from 'react';
import { highcharts } from 'App';
import HighchartsReact from 'highcharts-react-official';
import { dateFromMilliseconds } from 'components/stats/utils';
import { convertToTitle, getNumDays } from 'components/utils/utils';
import { getColorsCombination } from 'components/utils/colors';
import { tooltipStyle, labelsStyle, axisLabelsStyle } from './utils';

export type ColumnDataType = {
  id: string,
  name: string,
  valueLabel?: string,
  x: number,
  y: number,
  tooltipText?: string,
}

type formOptionsType =
 {
  period: string,
  startDate: string,
  endDate: string,
  groupingBy?: string,
}

type PropsIn = {
  data: ColumnDataType[],
  type?: string,
  pointWidth?: number,
  title?: string,
  measure?: string,
  categories?: string[],
  xAxysType?: 'datetime' | 'category',
  yAxis?: yAxisType[],
  rotateXLabel?: number,
  chartType?: string,
  loading?:boolean,
  height?: number,
  formOptions: formOptionsType;
  dashboardChart?: boolean;
}

export type yAxisType = {
  id: string,
  title: any,
  showEmpty: boolean,
}

const ColumnChart = (props: PropsIn) => {
  const {
    data, loading, height, type, chartType, categories, xAxysType,
    rotateXLabel, title, formOptions, dashboardChart,
  } = props;

  const series = data.filter((i) => i);

  series.forEach((item) => {
    if (item.type === 'spline' && Array.isArray(item.data)) {
      item.data.sort((a, b) => a.x - b.x);
    }
  });

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const colors = useMemo(() => getColorsCombination(), []);
  const [isZoomed, setIsZoomed] = useState(false);
  const {
    endDate, startDate, groupingBy, period,
  } = formOptions;
  const interval = getNumDays(endDate, startDate);
  const oneDayPeriod = interval === 0;
  const tickInterval = {
    daily: 24 * 3600 * 1000,
    weekly: 7 * 24 * 3600 * 1000,
    monthly: 30 * 24 * 3600 * 1000,
  };
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (!series.length) {
      chartComponentRef.current.chart.zoomOut();
    }
  }, [series]);

  useEffect(() => {
    if (chartComponentRef?.current) {
      if (loading) {
        chartComponentRef.current.chart.showLoading();
      } else {
        chartComponentRef.current.chart.hideLoading();
      }
    }
  }, [loading]);

  useEffect(() => {
    if (dashboardChart && chartComponentRef?.current?.chart?.container) {
      const chartContainer = chartComponentRef.current.chart.container;

      if (chartContainer instanceof Element) {
        const resizeObserver = new ResizeObserver(() => {
          setWidth(chartContainer.clientWidth || null);
        });

        resizeObserver.observe(chartContainer);

        return () => resizeObserver.disconnect();
      }
    }
  }, []);

  const getCustomPeriodInterval = (period:number) => {
    if (period <= 30) {
      return tickInterval.daily;
    } if (period > 30 && period <= 90) {
      return tickInterval.weekly;
    }
    return tickInterval.monthly;
  };

  function calculateTickInterval() {
    if (xAxysType === 'datetime') {
      if (groupingBy === 'session') {
        if (isZoomed) {
          return undefined;
        }
        if (period === 'custom' || period === 'all_season' || period === 'last_8_weeks') {
          return getCustomPeriodInterval(interval);
        }
        // last week/curr week/last month/curr month/last 7 days/last 30 days/last 4 weeks
        return tickInterval.daily;
      }
      // default: handled by highcharts (groupBy day/week/month)
      return undefined;
    }
    // if not datetime type, return xAxysType directly (for Summary charts)
    return xAxysType;
  }

  const options: Highcharts.Options = {
    chart: {
      animation: false,
      type: chartType || 'column',
      borderRadius: 12,
      width,
      height: height || 680,
      alignTicks: true,
      zoomType: 'x',
      events: {
        selection(event) {
          if (event.xAxis) {
            const chart = chartComponentRef.current?.chart;
            const xAxis = chart?.xAxis[0];
            setIsZoomed(true);
            xAxis.update({
              tickInterval: undefined,
            });
          } else {
            setIsZoomed(false);
          }
        },
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
    },
    legend: {
      enabled: true,
      verticalAlign: 'bottom',
      itemStyle: {
        fontWeight: '300',
      },
    },
    tooltip: {
      ...tooltipStyle,
      useHTML: true,
      shared: false,
      formatter() {
        return this.point?.tooltipText || `date: ${dateFromMilliseconds(this.x)} <br/> athlete: ${(this.point.name)}`;
      },
    },
    yAxis: series?.map((s) => ({
      id: s?.yAxis,
      title: {
        text: s?.uom,
        style: axisLabelsStyle,
      },
      showEmpty: false,
      labels: {
        ...axisLabelsStyle,
        formatter() {
          return this.value;
        },
      },
    })),
    xAxis: {
      id: 'xAxis',
      type: xAxysType,
      minPadding: 0.02,
      tickWidth: oneDayPeriod ? 0 : 1,
      tickInterval: calculateTickInterval(),
      title: {
        useHTML: true,
        style: axisLabelsStyle,
      },
      labels: {
        rotation: rotateXLabel || 0,
        align: rotateXLabel > 0 ? 'left' : 'right',
        style: !dashboardChart ? axisLabelsStyle : {
          ...axisLabelsStyle,
          fontSize: type === 'summary' && !dashboardChart ? '12px' : type === 'summary' && dashboardChart ? '10px' : '14px',
        },
        formatter() {
          if (xAxysType === 'datetime') {
            if (oneDayPeriod) {
              // show just 2 xlabels (start/end)
              const d = new Date(this.value);
              d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
              return this.isFirst || this.isLast ? d.toLocaleDateString('fr-CA') : '';
            }
            const d = new Date(this.value);
            d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
            return d.toLocaleDateString('fr-CA');
          }
          if (type === 'stats') {
            return convertToTitle(`${this.value}`);
          }
          return `${this.value}`;
        },
      },
      categories,
      ordinal: false,
      startOnTick: true,
      endOnTick: true,
      visible: series.length > 0,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    series,
    plotOptions: {
      series: {
        boostThreshold: 100,
        animation: false,
      },
      column: {
        maxPointWidth: 25,
        groupPadding: 0.1,
        borderWidth: 0,
        dataLabels: {
          crop: false,
          enabled: true,
          x: 0,
          y: dashboardChart ? -15 : -30,
          rotation: 270,
          style: {
            fontSize: '10px',
          },
          formatter() {
            if (this.point.pointWidth < 1) {
              return '';
            }
            return `<span style="fontFamily: var(--font-family-main); color: var(--text-secondary);">${this.point.y}</span>`;
          },
        },
      },
      spline: {
        marker: {
          enabled: true,
        },
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          formatter() {
            if (this.point.value_label) {
              return this.point.value_label.replace('&middot;', ' ');
            }
          },
          x: 3,
          y: -30,
          rotation: 270,
          style: {
            fontSize: '10px',
          },
        },
      },
    },
    colors,
  };

  return (
    <HighchartsReact
      highcharts={highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};

export { ColumnChart };
