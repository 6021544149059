import Layout, { largeScreen, MaintenanceMessage } from 'components/Layout';
import React, {
  Dispatch, SetStateAction, useCallback, useMemo, useState, useEffect, useRef,
} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from 'Login';
import { myStatsType, defaultMyStats } from 'components/stats/SeriesInputs2';
import { useMutation } from '@apollo/client';
import { REFRESH_TOKEN } from 'mutation/authentication';
import { REFRESH_TOKEN_INTERVAL } from 'components/constants';
import { APP_STATES, useGlobalState, AppState } from 'lib/global';
import { StoryType } from './components/Breadcrumbs';
import { TeamType } from './models/team';

export interface WebsocketRoomsState {
  label?: string,
  msg?: string,
  count: number,
  value: string | number,
  room: 'user' | 'team'
}

export const AuthenticatorContext = React.createContext<{
  isTokenValid: boolean;
  setIsTokenValid: React.Dispatch<React.SetStateAction<boolean>>;
    }>({
      isTokenValid: false,
      setIsTokenValid: () => false,
    });

export const WebsocketRoomsContext = React.createContext<{
  messages: {[k: string]: WebsocketRoomsState};
  setMessages: Dispatch<SetStateAction<{[k: string]: WebsocketRoomsState}>>;
    }>({
      messages: {},
      setMessages: () => {},
    });

export const TeamContext = React.createContext<{
  globalTeam?: TeamType;
  setGlobalTeam:(team: TeamType) => void;
    }>({
      globalTeam: undefined,
      setGlobalTeam: () => {},
    });

export const BreadCrumbsContext = React.createContext<{
  breadCrumbs?: StoryType;
  setBreadCrumbs:(story: StoryType) => void;
    }>({
      breadCrumbs: undefined,
      setBreadCrumbs: () => {},
    });

export const DocumentsContext = React.createContext<{
  refetchDocumentsStatus?: number;
  setRefetchDocumentsStatus:(status: number) => void;
    }>({
      refetchDocumentsStatus: 0,
      setRefetchDocumentsStatus: () => {},
    });

export const MyStatsContext = React.createContext<{
    myStats?: myStatsType;
    setMyStats:(stats) => void;
      }>({
        myStats: undefined,
        setMyStats: () => {},
      });

export const FilesTrackFilterContext = React.createContext<{
  incomingFileId?: string | null;
  setIncomingFileId:(incomingFileId: string | null) => void;
    }>({
      incomingFileId: '',
      setIncomingFileId: () => {},
    });

export const LoadingContext = React.createContext<{
  isLoading: boolean;
  setIsLoading:(value: boolean) => void;
    }>({
      isLoading: false,
      setIsLoading: () => {},
    });

export type ThemeOptions = {
  noHeaderMargins: boolean,
  sideBarOpen?: boolean,
  isTemplateSelectorActive: boolean,
}

export const ThemeOptionsContext = React.createContext<{
  themeOptions: ThemeOptions;
  setThemeOptions:(options: ThemeOptions) => void;
    }>({
      themeOptions: {
        noHeaderMargins: false,
        sideBarOpen: largeScreen(),
        isTemplateSelectorActive: true,
      },
      setThemeOptions: () => {},
    });

export default function () {
  const [globalTeam, setGlobalTeam] = useState<TeamType | undefined>();
  const [refetchDocumentsStatus, setRefetchDocumentsStatus] = useState(0);
  const [myStats, setMyStats] = useState<myStatsType | undefined>(defaultMyStats);
  const [incomingFileId, setIncomingFileId] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState<StoryType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [themeOptions, setThemeOptions] = useState<ThemeOptions>({
    noHeaderMargins: false,
    isTemplateSelectorActive: true,
    sideBarOpen: largeScreen(),
  });
  // @todo useMemo
  const teamValue = { globalTeam, setGlobalTeam }; // useMemo(() => ({ globalTeam, setGlobalTeam }), []);
  const documentsValue = { refetchDocumentsStatus, setRefetchDocumentsStatus };
  const myStatsValue = { myStats, setMyStats };
  const filesTrackFilterValue = { incomingFileId, setIncomingFileId };
  const breadCrumbsValue = { breadCrumbs, setBreadCrumbs }; // useMemo(() => ({ globalTeam, setGlobalTeam }), []);
  const isLoadingValue = { isLoading, setIsLoading }; // useMemo(() => ({ globalTeam, setGlobalTeam }), []);
  const themeOptionsValue = { themeOptions, setThemeOptions }; // useMemo(() => ({ globalTeam, setGlobalTeam }), []);

  const [appState] = useGlobalState(AppState);

  if (appState !== APP_STATES.normal) {
    return <MaintenanceMessage />;
  }

  return (
    <TeamContext.Provider value={teamValue}>
      <DocumentsContext.Provider value={documentsValue}>
        <MyStatsContext.Provider value={myStatsValue}>
          <FilesTrackFilterContext.Provider value={filesTrackFilterValue}>
            <BreadCrumbsContext.Provider value={breadCrumbsValue}>
              <LoadingContext.Provider value={isLoadingValue}>
                <ThemeOptionsContext.Provider value={themeOptionsValue}>
                  <WebsocketRoomsContextProvider>
                    <AuthenticatorContextProvider>
                      <BrowserRouter>
                        <Routes>
                          <Route path="/*" element={<Layout />} />
                          <Route path="/login" element={<Login />} />
                        </Routes>
                      </BrowserRouter>
                    </AuthenticatorContextProvider>
                  </WebsocketRoomsContextProvider>
                </ThemeOptionsContext.Provider>
              </LoadingContext.Provider>
            </BreadCrumbsContext.Provider>
          </FilesTrackFilterContext.Provider>
        </MyStatsContext.Provider>
      </DocumentsContext.Provider>
    </TeamContext.Provider>
  );
}

const WebsocketRoomsContextProvider: React.FC = ({ children }) => {
  const [messages, setMessagesData] = useState<{[k: string]: WebsocketRoomsState}>({});
  const setMessages = useCallback((msgs) => { setMessagesData(msgs); }, []);

  const messagesValue = useMemo(() => ({ messages, setMessages }), [messages]);

  return (
    <WebsocketRoomsContext.Provider value={messagesValue}>
      {children}
    </WebsocketRoomsContext.Provider>
  );
};


const AuthenticatorContextProvider: React.FC = ({children}) => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [backendChanged, setBackendChanged] = useState(false);
  const refreshJwtToken = localStorage.getItem('exelio_refresh_token');
  const isLoginPage = window.location.href.includes('login');

  const authenticatorValue = useMemo(() => ({ isTokenValid, setIsTokenValid }), [isTokenValid]);
  const now = Math.floor(Date.now() / 1000);
  const refreshTokenExpiration = Number(localStorage.getItem('exelio_refresh_token_exp') || 0);
  const isRefreshTokenExpired = refreshTokenExpiration < now;

  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    variables: {
      refreshToken: refreshJwtToken,
    },
    onCompleted(data) {
      console.log('Token refreshed successfully');
      const {
        token: newToken, refreshToken: newRefreshToken, refreshExpiresIn,
      } = data.res;
      localStorage.setItem('exelio_token', newToken);
      localStorage.setItem('exelio_refresh_token', newRefreshToken);
      localStorage.setItem('exelio_refresh_token_exp', JSON.stringify(refreshExpiresIn));
      setTimeout(() => setIsTokenValid(true), 250);
      setBackendChanged(false);
    },
    onError(error) {
      if (error.message) {
        const trimmedErrorMessage = error.message.trim();
        if (trimmedErrorMessage === 'Invalid refresh token') {
          setBackendChanged(true);
        }
      }
      console.error('Error refreshing token:', error);
      setTimeout(() => setIsTokenValid(false), 250);
    },
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !isLoginPage && refreshJwtToken && !isRefreshTokenExpired) {
        refreshToken(); // refresh token immediately when the tab becomes active (ex: after pc suspension)
      }
    };

    if (!isLoginPage && refreshJwtToken && !isRefreshTokenExpired) {
      refreshToken(); // refresh immediately on initial load
      const intervalId = setInterval(() => refreshToken(), REFRESH_TOKEN_INTERVAL); // periodic refresh (3 minutes)

      // listen to visibility change events
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        clearInterval(intervalId);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [isLoginPage, refreshJwtToken, isRefreshTokenExpired, refreshToken]);


  return (
    <AuthenticatorContext.Provider value={authenticatorValue}>
      {(isRefreshTokenExpired || backendChanged) ? <Login /> : children}
    </AuthenticatorContext.Provider>
  )
}
