import { useEffect, useRef } from 'react'

export function useTraceUpdate(props, name = '') {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log(`Changed props: ${name}`, changedProps)
    }
    prev.current = props
  })
}

export const messageToConsole = (
  message: string,
  type?: 'warn' | 'info' | 'error' | 'success',
  forceShow?: boolean,
) => {
  if (!forceShow && process.env.NODE_ENV === 'production') {
    return;
  }

  const commonStyle = 'padding: 3px;';

  const colors = {
    warn: `background: #916E05; color: #ffffff; ${commonStyle}`,
    info: `background: #98FAF5; color: #000000; ${commonStyle}`,
    error: `background: #910505; color: #ffffff; ${commonStyle}`,
    success: `background: #BAFDB3; color: #000000; ${commonStyle}`,
  };

  console.log(`%c${message}`, colors[type || 'info']);
};
