import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import WebPlayerNew from './webPlayerNew/WebPlayer';

/**
 * Componente per effettuare test in fase di sviluppo su una rotta `/test-components/current`
 *
 * @description NON COMMITTARE le modifiche. Usarlo solo per testare componenti singolarmente.
 *
 * @constructor
 */
const TestComponentsNewPlayer = () => {
  return (
    <div style={{ maxWidth: '1600px' }}>
      <Paper>
        <Box p={2} mb={3}>
          <WebPlayerNew currentDrill={null} sessionId="62003" teamId="397" templateId="1570" />
        </Box>
      </Paper>
    </div>
  );
};

export default TestComponentsNewPlayer;
