import React, { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { Handle } from './fragments/Handle';

export type ItemProps = {
  id: string;
  component: React.ReactElement;
}

export  const Item: FC<ItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id
    });
  return (
    <li
      className="dsl__item"
      ref={setNodeRef}
      {...attributes}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        listStyle: "none",
      }}
      // className={styles["list-item"]}
    >
      {props.component}
      <Handle {...listeners} />
    </li>
  );
};


export  interface ListProps {
  id: string;
  title: string;
  items: ItemProps[];
}

export const List: FC<ListProps> = (props) => {
  const { setNodeRef, isOver } = useDroppable({ id: props.id });

  return (
    <div
      // className={styles.column}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      className={`dsl__list ${isOver ? "dsl__list--over" : ""}`}
      ref={setNodeRef}
    >
      <h3>{props.title}</h3>
      <SortableContext id={props.id} items={props.items}>
        <ul
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              transition: "background-color 0.3s ease-in-out",
            }}
        >
          {props.items.map((task) => (
            <Item key={task.id} id={task.id} component={task.component} />
          ))}
        </ul>
      </SortableContext>
    </div>
  );
};

export interface DoubleListIDs {
  [k: string]: {
    title: string;
    items: string[];
  }
}
