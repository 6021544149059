import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { TSCATEGORY, TeamSessionCategoryTypeRes, TeamSessionCategoryTypeVars } from 'query/category';
import {
  ReportSeriesVars,
  ReportSeriesRes,
  REPORT_SERIES,
} from 'query/track';
import { camelCaser } from 'components/utils/kpiLabelExtractor';
import { TSTAG, TeamSessionTagsTypeRes, TeamSessionTagsTypeVars } from 'query/tags';
import { GET_TEAM_PLAYERS } from 'query/team';
import { useQueryCached } from 'components/utils/graphql';
import { orderKpis } from 'components/templates/utils';
import {
  AthleteAndRolesRes,
  ATHLETES,
  ATHLETES_AND_ROLES,
  ATHLETES_BASIC,
  AthleteTypeRes,
  AthleteTypeVars,
} from '../../query/athlete';
import { capitalize } from './Filters';
import { CACHE_AND_NETWORK } from '../../lib/cache';

export const calcDate = (offset: number) => {
  const today: Date = new Date();
  let day: number = today.getDate() + offset;
  let month: number = today.getMonth();
  let year: number = today.getFullYear();

  const daysThisMonth: number = new Date(year, month + 1, 0).getDate();

  if (day > daysThisMonth) {
    day -= daysThisMonth;
    month += 1;
    if (month > 11) {
      month = 0;
      year += 1;
    }
  } else if (day < 0) {
    const daysPreviousMonth: number = new Date(year, month, 0).getDate();
    day += daysPreviousMonth;
    month -= 1;
    if (month < 0) {
      month = 11;
      year -= 1;
    }
  }

  return new Date(year, month, day);
};

export const PeriodFormatter = (period: string) => {
  switch (period) {
    case 'all_season':
      return 'all season';
    case 'current_week':
      return 'current week';
    case 'previous_week':
      return 'previous week';
    case 'current_month':
      return 'current month';
    case 'previous_month':
      return 'previous month';
    case 'custom':
      return 'custom';
    case 'last_7_days':
      return 'last 7 days';
    case 'last_30_days':
      return 'last 30 days';
    case 'last_4_weeks':
      return 'last 4 weeks';
    case 'last_8_weeks':
      return 'last 8 weeks';
    default:
      return '-';
  }
};

export function athleteExtractor(team: string, trendQuery?: boolean) {
  const WHICH_QUERY = !trendQuery ? ATHLETES : ATHLETES_AND_ROLES;
  const { loading, error, data } = useQueryCached<{res: AthleteTypeRes}|AthleteAndRolesRes, AthleteTypeVars>(WHICH_QUERY, {
    variables: {
      teamId: team,
      sort: 'last_name',
      inUse: true,
    },
    ...CACHE_AND_NETWORK,
  });

  return loading ? [] : error ? [] : data;
}

export async function athleteOptionsExtractor(teamId: string, trendQuery?: boolean) {
  const client = useApolloClient();
  try {
    const WHICH_QUERY = !trendQuery ? ATHLETES_BASIC : ATHLETES_AND_ROLES;
    const { data: athletesQuery } = await client.query<{res: AthleteTypeRes}|AthleteAndRolesRes, AthleteTypeVars>({
      query: WHICH_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        teamId,
        sort: 'last_name',
        inUse: true,
        onDate: null,
      },
    });
    const { data: playerQuery } = await client.query({
      query: GET_TEAM_PLAYERS,
      fetchPolicy: 'no-cache',
      variables: {
        id: teamId,
        getCategory: false,
        getTags: false,
        activeOn: null,
      },
    });
    const athletesOptions = athletesQuery?.res?.content.map((obj1) => {
      const matchingObj = playerQuery?.res?.playerSet.find((obj2) => obj2.athlete.id === obj1.id);
      return {
        id: obj1.id,
        value: obj1.name ? capitalize(obj1.name) : 'UNASSIGNED',
        groupBy: 'athlete',
        startDate: matchingObj?.startDate,
        endDate: matchingObj?.endDate,
      };
    });

    return athletesOptions;
  } catch (error) {
    console.error('Error fetching athlete options:', error);
    return [];
  }
}

export async function trendAthleteRoleOptionsExtractor(teamId: string) {
  const client = useApolloClient();
  try {
    const { data: athletesQuery } = await client.query<{res: AthleteTypeRes}|AthleteAndRolesRes, AthleteTypeVars>({
      query: ATHLETES_AND_ROLES,
      fetchPolicy: 'no-cache',
      variables: {
        teamId,
        sort: 'last_name',
        inUse: true,
        onDate: null,
      },
    });

    const { data: playerQuery } = await client.query({
      query: GET_TEAM_PLAYERS,
      fetchPolicy: 'no-cache',
      variables: {
        id: teamId,
        getCategory: false,
        getTags: false,
        activeOn: null,
      },
    });

    const athletesOptions = athletesQuery?.resAthletes?.content?.map((obj1) => {
      const matchingObj = playerQuery?.res?.playerSet.find((obj2) => obj2.athlete.id === obj1.id);
      return {
        id: obj1.id,
        value: obj1.name ? capitalize(obj1.name) : 'UNASSIGNED',
        groupBy: 'athlete',
        startDate: matchingObj?.startDate,
        endDate: matchingObj?.endDate,
      };
    });
    const rolesOptions = [{ id: 'team', value: 'TEAM', groupBy: 'role' }].concat(
      athletesQuery?.resRoles?.content?.map((r) => ({ id: r.id, value: r.name, groupBy: 'role' })),
    );
    const athletesRolesOptions = rolesOptions?.concat(athletesOptions);
    return athletesRolesOptions;
  } catch (error) {
    console.error('Error fetching athleteRole options:', error);
    return [];
  }
}

export async function categoryOptionsExtractor(teamId: string) {
  const client = useApolloClient();
  try {
    const { data } = await client.query<{res: TeamSessionCategoryTypeRes}, TeamSessionCategoryTypeVars>({
      query: TSCATEGORY,
      fetchPolicy: 'no-cache',
      variables: {
        teamId,
        sort: 'name',
        hasTeamsession: false,
        bypassPermission: true,
      },
    });
    const categoriesOptions = data?.res?.content?.map((c) => ({
      id: c.id,
      value: c.name.toUpperCase(),
      color: c.color,
    }));
    return categoriesOptions;
  } catch (error) {
    console.error('Error fetching category options:', error);
    return [];
  }
}

export async function kpiOptionsExtractor(teamId:string) {
  const client = useApolloClient();
  try {
    const { t } = useTranslation();
    const { data } = await client.query<ReportSeriesRes, ReportSeriesVars>(
      {
        query: REPORT_SERIES,
        fetchPolicy: 'no-cache',
        variables: {
          teamId,
          excludeFields: ['athlete', 'short_name', 'jersey', 'teamsessions_count', 'average_time',
            'tot_mechanical_events'],
        },
      },
    );

    const kpiList = data?.reportSeries?.reduce((acc, kpi) => {
      // remove external_work_negative
      if (kpi.key !== 'external_work_negative') {
      // move HR kpis from main/basic to HR zones/heart rate
        const label = t(
          `kpi2.${camelCaser(kpi.group, kpi.key)}.label`,
          kpi.key.split('_').join(' '),
        );
        const group = (kpi.group === 'main' && (kpi.unit === 'hr' || kpi.unit === 'percentage'))
          ? t('kpi2.HRZones.title', 'heart rate')
          : t(`kpi2.${camelCaser(kpi.group)}.title`, kpi.group);
        acc.push({ ...kpi, label, group });
      }
      return acc;
    }, []);

    const kpiOptions = [{ groupBy: 'basic', id: 'teamsessions_count', value: 'sessions' },
      { groupBy: 'basic', id: 'average_time', value: 'duration' }].concat(kpiList?.map((k) => ({
      id: k.key,
      value: k.label,
      groupBy: k.group,
    })));

    kpiOptions.sort((a, b) => {
      const indexA = orderKpis.indexOf(a.groupBy);
      const indexB = orderKpis.indexOf(b.groupBy);
      return indexA - indexB;
    });
    return kpiOptions;
  } catch (error) {
    console.error('Error fetching kpi options:', error);
    return [];
  }
}

export async function tagOptionsExtractor(teamId:string) {
  const client = useApolloClient();
  try {
    const { data } = await client.query<{res: TeamSessionTagsTypeRes}, TeamSessionTagsTypeVars>({
      query: TSTAG,
      fetchPolicy: 'no-cache',
      variables: {
        teamId,
        sort: 'name',
      },
    });
    const tagsOptions = data?.res?.content?.map((t) => ({
      id: t.id,
      value: t.name,
    }));
    return tagsOptions;
  } catch (error) {
    console.error('Error fetching tag options:', error);
    return [];
  }
}

export const dateFromMilliseconds = (ms) => {
  if (ms) {
    return new Date(parseInt(ms)).toISOString().split('T')[0];
  }
  return '-';
};

export const copyStatsUrl = (
  teamId: string, 
  statsType: 'trend' | 'summary' | 'radar', 
  statsSession: string, 
  userId: string,
) => {
  const { host } = window.location;
  const protocol = host === 'localhost:8080' ? 'http' : 'https';
  const url = `${protocol}://${host}/stats?statsType=${statsType}&statsSession=${statsSession}&filtersTeam=${teamId}&user=${userId}`;

  return url;
};
