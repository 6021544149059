import React, { useCallback } from 'react';
import { Circle, Group, Path, Text } from 'react-konva';
import { MarkerProps } from 'types/webPlayer';

const unitsMapping = {
  s: 'speed',
  hr: 'hr'
}

const tooltipSvg = (x: number, y: number, w: number, text: string[], curve = 4) => {

  const h = text.length * 14 + 8;

  return (
    <Group
      x={x}
      y={y}
      className="marker-tooltip"
    >
      <Path
        fill={'white'}
        stroke={'#555'}
        strokeWidth={1}
        opacity={0.8}
        shadowEnabled
        shadowBlur={5}
        shadowColor={'#000'}
        shadowOpacity={0.5}
        shadowOffsetY={3}
        data={`M 0 ${curve}
    L 0 ${h - 1}
    Q 0 ${h} 1 ${h} 
    L ${w - 1} ${h}
    Q ${w} ${h} ${w} ${h - 1} 
    L ${w} ${curve} 
    Q ${w} 3 ${w - 1} 3 
    L ${w / 2 - 2} 3 
    L ${w / 2} 0 
    L ${w / 2 + 2} 3 
    L 1 3 
    Q 0 3 0 ${curve}`}></Path>
      {
        text.map((t, idx) => (<Text
          text-anchor="middle"
          x={5}
          y={14 * idx + 8}
          text={t}
          fill={'#222'}
          fontSize={10}
          fontFamily="Roboto"
        />))
      }
    </Group>)
};

const Marker = React.memo(( props: MarkerProps ) => {
  const {
    active,
    color,
    cursor,
    hover,
    id,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    pathData,
    scale,
    showExtraData,
    text,
    textColor,
    userUnits,
  } = props;

  const handleClick = useCallback(() => onClick(id), [id, onClick]);
  const handleDblClick = useCallback(() => onDblClick?.(id), [id, onDblClick]);
  const handleEnter = useCallback(() => onMouseEnter?.(id), [id, onMouseEnter]);
  const handleLeave = useCallback(() => onMouseLeave?.(id), [id, onMouseLeave]);

  const position = pathData?.[cursor];
  const posX = position?.x !== null && position?.x !== undefined ? position.x * scale : 0;
  const posY = position?.y !== null && position?.y !== undefined ? position.y * scale : 0;

  if (!position) {
    return null;
  }

  const tooltipTextData = [
    ...position.s ? [`${position.s?.toFixed(2)} ${userUnits && userUnits[unitsMapping.s] || ''}`] : [],
    ...position.hr ? [`${position.hr?.toFixed(0)} ${userUnits && userUnits[unitsMapping.hr] || 'hr'}`] : [],
  ];

  return tooltipTextData.length === 0 ? null : (
    <>
      {
        position.h && (
          <Group
            rotation={position.h || undefined}
            x={posX}
            y={posY}
          >
            <Path
              data="M13.7715 22.0564C16.5641 23.8395 20.2734 23.0211 22.0564 20.2285C23.8395 17.4359 23.0211 13.7266 20.2285 11.9436C17.436 10.1605 13.7267 10.9789 11.9436 13.7715C10.1605 16.5641 10.9789 20.2734 13.7715 22.0564Z"
              fill={color}
              offsetX={17}
              offsetY={21}
            />
            <Path
              data="M34 8.33333C34 7.57893 32.9474 7.5023 32.6467 8.19419C30.0563 14.1548 24.0259 18.3333 17 18.3333C9.97412 18.3333 3.94371 14.1548 1.35331 8.19419C1.05262 7.5023 0 7.57893 0 8.33333C0 17.5381 7.61116 25 17 25C26.3888 25 34 17.5381 34 8.33333Z"
              fill={color}
              offsetX={17}
              offsetY={21}
            />
          </Group>
        )
      }
      {
        !position.h && (
          <Circle
            fill={color}
            onClick={handleClick}
            onDblClick={handleDblClick}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            radius={active || hover ? 8 : 5}
            stroke={active ? '' : hover ? 'orange' : 'white'}
            x={posX}
            y={posY}
          />
        )
      }

      <Text
        align="center"
        text={text}
        x={posX - text.length * 4}
        y={posY - 17}
        fill={textColor || '#828282'}
        fontSize={12}
        fontFamily="Roboto"
      />

      {
        showExtraData &&
        tooltipSvg(
          posX - 30,
          posY + 10,
          60,
          tooltipTextData
        )
      }
    </>
  );
});

export default Marker;
