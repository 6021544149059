import { useTour } from '@reactour/tour';
import React from 'react';
import BaseButton from '../form/BaseButton';
import InfoIcon from '@material-ui/icons/Info';

export const steps = [
  {
    selector: '.konvajs-content',
    content: 'This is the player view',
  },
  {
    selector: '.player-slider-container',
    content: 'This is the player slider, you click to change the position. Loaded data is shown as a green area',
  },
  {
    selector: '.player-time-label',
    content: 'This is the player time label, you click to change from absolute to relative time',
  },
  {
    selector: '.player-time-controls',
    content: (<>
      This are the player time controls, you can change the speed of the player, change speed, move to next/prev drill, go full screen and play/pause. You can also use:<ul><li>Arrow Left/Arrow Right to move forward/backward 1 seconds</li><li>Shift key + Arrow Left/Arrow Right to move forward/backward 10 seconds</li><li>Space to play/pause</li></ul>
    </>),
  }
];

const TourGuide = () => {
  const { setIsOpen } = useTour();

  return (
    <>
      <BaseButton isIconButton noMargins onClick={() => setIsOpen(true)}>
        <InfoIcon />
      </BaseButton>
    </>
  );
};

export default TourGuide;
