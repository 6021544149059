import { computeDestinationPoint, getCenterOfBounds, getDistance, getRhumbLineBearing } from 'geolib';

import {
  computeAngle, flipPoint, getScale, rotate,
} from '../components/track/ground/utils';
import { GenericValueType } from '../query/session';
import { GroundType } from '../models/ground';

export interface GPSCoords {
  latitude: number;
  longitude: number;
}

export type PathData = Array<{x: number | null, y: number | null, t: number, s: number | null, h: number | null, hr?: number | null}>

export const geolibAccuracy = 0.01;

export const gpsToLocal = (gps1: GPSCoords, gps2: GPSCoords, angle = 0, abs = false) => {
  if (!gps1.latitude || !gps1.longitude || !gps2.latitude || !gps2.longitude) {
    return {
      x: 0,
      y: 0,
    }
  }

  const dx = (gps1.latitude >= gps2.latitude ? -1 : 1) * getDistance(
    { latitude: gps1.latitude, longitude: gps1.longitude },
    { latitude: gps2.latitude, longitude: gps1.longitude },
    geolibAccuracy,
  );

  const dy = (gps1.longitude >= gps2.longitude ? -1 : 1) * getDistance(
    { latitude: gps1.latitude, longitude: gps1.longitude },
    { latitude: gps1.latitude, longitude: gps2.longitude },
    geolibAccuracy,
  );

  if (angle !== 0) {
    // Converti l'angolo da gradi a radianti
    const theta = angle * Math.PI / 180;

    const x = dx * Math.cos(theta) - dy * Math.sin(theta);
    const y = dx * Math.sin(theta) + dy * Math.cos(theta);

    return {
      x: abs ? Math.abs(x) : x,
      y: abs ? Math.abs(y) : y,
    };
  }

  return {
    x: abs ? Math.abs(dx) : dx,
    y: abs ? Math.abs(dy) : dy,
  };
};

export const getBearing = (gps1: GPSCoords, gps2: GPSCoords) => gps1.latitude && gps1.longitude && gps2.latitude && gps2.longitude && getRhumbLineBearing(
  { latitude: gps1.latitude, longitude: gps1.longitude },
  { latitude: gps2.latitude, longitude: gps2.longitude },
);

export const LPSFromGPSPath = (
  path: PathData,
  positionA: GPSCoords,
  positionB: GPSCoords,
  positionC: GPSCoords,
  scale = 1,
) => {

  if (!positionA.latitude || !positionB.latitude) {
    return [];
  }

  const bearing = getBearing(
    {
      latitude: positionA.latitude,
      longitude: positionA.longitude,
    },
    {
      latitude: positionB.latitude,
      longitude: positionB.longitude,
    },
  );

  const offsetY = getDistance(
    {
      latitude: positionA.latitude,
      longitude: positionA.longitude,
    },
    {
      latitude: positionB.latitude,
      longitude: positionB.longitude,
    },
    geolibAccuracy,
  );
  const offsetX = getDistance(
    {
      latitude: positionB.latitude,
      longitude: positionB.longitude,
    },
    {
      latitude: positionC.latitude,
      longitude: positionC.longitude,
    },
    geolibAccuracy,
  );

  return path.map((p) => {
    if (!p.x || !p.y) {
      return {
        t: p.t,
        x: null,
        y: null,
        s: p.s,
        h: p.h,
        hr: p.hr,
      };
    }

    const { x, y } = gpsToLocal(
      {
        latitude: p.x,
        longitude: p.y,
      },
      {
        latitude: positionC.latitude,
        longitude: positionC.longitude,
      },
      90 - bearing,
    );

    return {
      t: p.t,
      x: x * scale + offsetX * scale,
      y: y * scale - offsetY * scale,
      x_0: p.x,
      y_0: p.y,
      s: p.s,
      h: p.h,
      hr: p.hr,
    };
  });
};

type VertexType = { x: number; y: number };

interface GPSGroundReturnType {
  vertexA: VertexType;
  vertexC: VertexType;
  width: number;
  height: number;
  scale: number;
  scaledWidth: number;
  scaledHeight: number;
}

export const GPSGround = (props: Partial<GroundType>, boxWidth: number, boxHeight: number): GPSGroundReturnType => {
  const {
    vertexAX,
    vertexAY,
    vertexCX,
    vertexCY,
  } = props;

  let vertexA = {
    x: typeof vertexAX?.value === 'number' ? vertexAX?.value : parseFloat(vertexAX?.value),
    y: typeof vertexAY?.value === 'number' ? vertexAY?.value : parseFloat(vertexAY?.value),
  };
  let vertexC = {
    x: typeof vertexCX?.value === 'number' ? vertexCX?.value : parseFloat(vertexCX?.value),
    y: typeof vertexCY?.value === 'number' ? vertexCY?.value : parseFloat(vertexCY?.value),
  };

  if (vertexA?.x !== undefined && vertexC?.x !== undefined && vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  if (vertexA?.y !== undefined && vertexC?.y !== undefined && vertexA?.y < vertexC?.y) {
    const swap = vertexC.y;
    vertexC.y = vertexA.y;
    vertexA.y = swap;
  }

  const width = vertexC.x !== undefined && vertexA.x !== undefined
    ? vertexC.x - vertexA.x
    : 0;

  const height = vertexC.y !== undefined && vertexA.y !== undefined
    ? vertexA.y - vertexC.y
    : 0;
  const scale = getScale(width, height, boxWidth, boxHeight) * 0.85;

  vertexA = {
    x:
      rotate(
        flipPoint({ x: vertexAX?.value, y: vertexAY?.value }, props),
        computeAngle(props),
      ).x * scale,
    y:
      rotate(
        flipPoint({ x: vertexAX?.value, y: vertexAY?.value }, props),
        computeAngle(props),
      ).y * scale,
  };
  vertexC = {
    x:
      rotate(
        flipPoint({ x: vertexCX?.value, y: vertexCY?.value }, props),
        computeAngle(props),
      ).x * scale,
    y:
      rotate(
        flipPoint({ x: vertexCX?.value, y: vertexCY?.value }, props),
        computeAngle(props),
      ).y * scale,
  };

  const scaledWidth = vertexC.x !== undefined && vertexA.x !== undefined ? vertexC.x - vertexA.x : 1;
  const scaledHeight = vertexA.y - vertexC.y ? vertexA.y - vertexC.y : 1;

  return {
    vertexA,
    vertexC,
    width,
    height,
    scale,
    scaledWidth,
    scaledHeight,
  };
};

export interface LPSToGPSGroundProps {
  vertexAX: GenericValueType;
  vertexAY: GenericValueType;
  vertexCX: GenericValueType;
  vertexCY: GenericValueType;
}

export const LPSToGPSGround = (props: LPSToGPSGroundProps) => {
  const {
    vertexAX,
    vertexAY,
    vertexCX,
    vertexCY,
  } = props;

  let vertexA = {
    x: typeof vertexAX?.value === 'number' ? vertexAX?.value : parseFloat(vertexAX?.value),
    y: typeof vertexAY?.value === 'number' ? vertexAY?.value : parseFloat(vertexAY?.value),
  };
  let vertexC = {
    x: typeof vertexCX?.value === 'number' ? vertexCX?.value : parseFloat(vertexCX?.value),
    y: typeof vertexCY?.value === 'number' ? vertexCY?.value : parseFloat(vertexCY?.value),
  };

  if (vertexA?.x !== undefined && vertexC?.x !== undefined && vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  if (vertexA?.y !== undefined && vertexC?.y !== undefined && vertexA?.y < vertexC?.y) {
    const swap = vertexC.y;
    vertexC.y = vertexA.y;
    vertexA.y = swap;
  }

  const width = vertexC.x !== undefined && vertexA.x !== undefined
    ? vertexC.x - vertexA.x
    : 0;

  const height = vertexC.y !== undefined && vertexA.y !== undefined
    ? vertexA.y - vertexC.y
    : 0;
  const scale = getScale(width + 10, height + 10);

  vertexA = {
    x:
      rotate(
        flipPoint({ x: vertexAX?.value, y: vertexAY?.value }, props),
        computeAngle(props),
      ).x * scale,
    y:
      rotate(
        flipPoint({ x: vertexAX?.value, y: vertexAY?.value }, props),
        computeAngle(props),
      ).y * scale,
  };
  vertexC = {
    x:
      rotate(
        flipPoint({ x: vertexCX?.value, y: vertexCY?.value }, props),
        computeAngle(props),
      ).x * scale,
    y:
      rotate(
        flipPoint({ x: vertexCX?.value, y: vertexCY?.value }, props),
        computeAngle(props),
      ).y * scale,
  };

  const scaledWidth = vertexC.x !== undefined && vertexA.x !== undefined ? vertexC.x - vertexA.x : 1;
  const scaledHeight = vertexA.y - vertexC.y ? vertexA.y - vertexC.y : 1;

  return {
    vertexA,
    vertexC,
    width,
    height,
    scale,
    scaledWidth,
    scaledHeight,
  };
};

export const deg2rad = (degrees: number) => {
  return degrees * (Math.PI / 180);
}

export const rad2deg = (radians: number) => {
  return radians * (180 / Math.PI);
}

export const calculateNewPoint = (start: GPSCoords, bearing: number, distance: number): GPSCoords => {
  const R = 6371; // Radius of the Earth in kilometers
  const angDist = distance / R; // Angular distance in radians
  const baringAng = deg2rad(bearing); // Bearing in radians

  const radLatitude = deg2rad(start.latitude);
  const radLongitude = deg2rad(start.longitude);

  const newRadLatitude = Math.asin(Math.sin(radLatitude) * Math.cos(angDist) + Math.cos(radLatitude) * Math.sin(angDist) * Math.cos(baringAng));
  const newRadLongitude = radLongitude + Math.atan2(Math.sin(baringAng) * Math.sin(angDist) * Math.cos(radLatitude), Math.cos(angDist) - Math.sin(radLatitude) * Math.sin(newRadLatitude));

  const latitude = rad2deg(newRadLatitude);
  const longitude = rad2deg(newRadLongitude);

  return { latitude, longitude };
}

export const generateInnerRectangle = (
  percent: number,
  coordinates: [GPSCoords, GPSCoords, GPSCoords, GPSCoords],
): [GPSCoords, GPSCoords, GPSCoords, GPSCoords] => {
  /*if (percent <= 0 || percent >= 100) {
    throw new Error('Percent must be between 0 and 100 (exclusive)');
  }*/

  const center = getCenterOfBounds(coordinates);

  const ratio = Math.sqrt(percent / 100);

  return coordinates.map((coord) => {
    const bearing = getRhumbLineBearing(center, coord);
    const initialDistance = getDistance(center, coord);
    const reducedDistance = initialDistance * ratio;

    return computeDestinationPoint(center, reducedDistance, bearing);
  }) as [GPSCoords, GPSCoords, GPSCoords, GPSCoords];
}
