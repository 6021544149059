import { Box, Slider, Tooltip, Typography, withStyles, } from '@material-ui/core';
import format from 'date-fns/format';
import React, { useCallback, useMemo, useState } from 'react';
import HeaderPopover from 'components/layout/HeaderPopover';
import BaseButton from '../form/BaseButton';
import { MarkDrill, PlayerControlsProps, PlayerDataIntervals, SliderDrillsProps } from '../../types/webPlayer';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import StopIcon from '@material-ui/icons/Pause';
import X05 from '@images/icons/svg/X0.5.svg';
import X1 from '@images/icons/svg/X1.svg';
import X25 from '@images/icons/svg/X2.5.svg';
import X5 from '@images/icons/svg/X5.svg';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    top: '10px',
  },
}))(Tooltip);

const SliderDrills = ( props: SliderDrillsProps ) => {
  const {
    min, max, marks, currentTime,
  } = props;

  const isDrillSelected = ( startTime, endTime ) => currentTime >= startTime && currentTime <= endTime;

  const marksBars = useMemo(() => {
    const out: [MarkDrill, MarkDrill][] = [];
    for (let i = 0; i < marks.length; i += 2) {
      out.push([marks[i], marks[i + 1]]);
    }

    return out;
  }, [marks]);

  if (min && max && marks && marks.length) {
    const range = max - min;

    return (
      <Box className="player-marks-overlay">
        {
          marksBars.map(( bar ) => (
            <Box
              key={bar[0].index}
              position="absolute"
              left={`${(bar[0].value - min) / range * 100}%`}
              width={`${(bar[1].value - bar[0].value) / range * 100}%`}
            >
              <CustomTooltip
                title={`${bar[0].label} - ${bar[1].label}`}
                arrow
                placement="top"
              >
                <span style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: isDrillSelected(bar[0].value, bar[1].value) ? 800 : 400,
                }}
                >
                  {`D${bar[0].index}`}
                </span>
              </CustomTooltip>
            </Box>
          ))
        }
      </Box>
    );
  }

  return null;
};

const SliderDataStatus = ( props: {
  min: number,
  max: number,
  marks: PlayerDataIntervals,
} ) => {
  const {
    min, max, marks,
  } = props;

  if (min && max && marks && marks.length) {
    const range = max - min;

    return (
      <Box className="player-data-overlay">
        {
          marks.map(( bar ) => (
            <Box
              key={bar[0]}
              position="absolute"
              left={`${(bar[0] - min) / range * 100}%`}
              width={`${(bar[1] - bar[0]) / range * 100}%`}
            />
          ))
        }
      </Box>
    );
  }

  return null;
};

const PlayerControls = ( props: PlayerControlsProps ) => {
  const {
    currDrillRef,
    currentDrill,
    currentTime,
    drillTimes,
    enabled,
    endTimestamp,
    goToNextDrill,
    goToPrevDrill,
    handleChange,
    handleFullscreenClick,
    handlePlayClick,
    intervals,
    marks,
    playerSpeed,
    playerStatus,
    setPlayerSpeed,
    startTimestamp,
    timeFormat,
    timestamps,
    valueLabelFormat,
  } = props;

  const timezoneOffset = startTimestamp
    ? -new Date(startTimestamp).getTimezoneOffset() * 60000
    : 0;
  const localTimezoneOffset = -new Date().getTimezoneOffset() * 60000;

  const startDrill = drillTimes && drillTimes.length > 0 && currentDrill !== null && drillTimes[currentDrill].start;
  const endDrill = drillTimes && drillTimes.length > 0 && currentDrill !== null && drillTimes[currentDrill].end;

  const startTime = startDrill
    ? startDrill
    : startTimestamp
      ? new Date(startTimestamp).getTime() + timezoneOffset
      : 0;
  const endTime = endDrill
    ? endDrill
    : endTimestamp
      ? new Date(endTimestamp).getTime() + timezoneOffset
      : 0;

  const [hoverTime, setHoverTime] = useState<number | undefined>(currentTime);
  const [showRelativeTime, setShowRelativeTime] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({left: 0, top: 0});

  const getRealTime = ( time: number ) => {
    if (time !== undefined && time >= 0) {
      return format(time - (showRelativeTime ? startTime : 0) - (showRelativeTime ? localTimezoneOffset : timezoneOffset), timeFormat);
    }
  }

  // get currentTime based on mouse position on slider:
  const getCurrentTimeValue = ( event ) => {
    const slider = event.currentTarget;
    const rect = slider.getBoundingClientRect();
    const xPos = event.clientX - rect.left;
    return Math.round((xPos / rect.width) * (endTime - startTime) + startTime);
  };

  const updateCurrentDrill = ( currTime ) => {
    if (currTime === 0) return;

    drillTimes.forEach(( {start}, index ) => {
      if (currTime >= start && currTime <= drillTimes[index + 1].end) {
        currDrillRef.current = index + 1;
      }
    });
  };

  const handleSliderChange = useCallback(( event, newValue ) => {
    setHoverTime(undefined);
    if (enabled) {
      handleChange(event, newValue);
      if (Object.hasOwn(event, 'getBoundingClientRect')) {
        const currTime = getCurrentTimeValue(event);
        updateCurrentDrill(currTime);
      }
    }
  }, [handleChange, enabled]);

  const handleMouseMove = useCallback(( event ) => {
    const slider = event.currentTarget;
    const rect = slider.getBoundingClientRect();
    const xPos = event.clientX - rect.left;
    const currTime = Math.round((xPos / rect.width) * (endTime - startTime) + startTime);
    setHoverTime(currTime);
    setTooltipPosition({
      left: xPos,
      top: 18,
    });
  }, [endTime, startTime]);

  const handleMouseLeave = useCallback(() => setHoverTime(undefined), [])
  const handlePlay = useCallback(( event: React.MouseEvent<HTMLButtonElement> ) => {
    event.currentTarget.blur()
    handlePlayClick();
  }, [handlePlayClick])

  return (
    <Box style={{position: 'relative'}}>
      <Box mt={2} display="flex" alignItems="center" style={{padding: '16px 0px'}}>
        <Box width="calc(100% - 6px)" position="relative" className="player-slider-container">
          {hoverTime !== undefined && (
            <Tooltip
              title={getRealTime(hoverTime) || ''}
              placement="top"
              open={!!hoverTime}
              arrow
              style={{
                position: 'absolute',
                left: tooltipPosition.left,
                top: tooltipPosition.top,
              }}
            >
              <span/>
            </Tooltip>
          )}

          <Slider
            value={currentTime}
            valueLabelFormat={valueLabelFormat}
            // marks={currentDrill === null && marks.map((m) => ({...m, value: m.idx || 0}))}
            min={startTime}
            max={endTime}
            onChange={handleSliderChange}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
          {currentDrill === null && (
            <SliderDrills
              marks={marks}
              min={startTime}
              max={endTime}
              // currentTimeIdx={currentTimeIdx}
              currentTime={currentTime}
              timestamps={timestamps}
            />
          )}
          <SliderDataStatus
            min={startTime}
            max={endTime}
            marks={intervals}
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box width="80px" className="player-time-label" alignItems="center" display="flex">
          <Typography
            gutterBottom
            title={`switch to ${showRelativeTime ? 'H:m:s' : 'relative time'}`}
            onClick={() => setShowRelativeTime(( prev ) => !prev)}
            style={{
              cursor: 'pointer',
              margin: '0'
            }}
          >
            {startTimestamp ? getRealTime(startTime) : '--:--:--'}
          </Typography>
        </Box>

        <Box width="100%" display="flex" gridGap="10px" className="player-time-controls">
          <Box minWidth="180px" display="flex" gridGap="4px" alignItems="center" justifyContent="flex-start" ml="2rem">
            {marks.length > 0
              && (
                <BaseButton
                  isIconButton
                  noMargins
                  onClick={() => {
                    goToPrevDrill();
                  }}
                >
                  <SkipPreviousIcon/>
                </BaseButton>
              )}
            <BaseButton isIconButton noMargins onClick={handlePlay}>
              {playerStatus === 0 && <PlayArrowIcon/>}
              {playerStatus === 1 && <StopIcon/>}
            </BaseButton>
            {marks.length > 0
              && (
                <BaseButton
                  isIconButton
                  noMargins
                  onClick={() => {
                    goToNextDrill();
                  }}
                >
                  <SkipNextIcon/>
                </BaseButton>
              )}
          </Box>

          <Box width="100%" textAlign="center" display="flex" alignItems="center" justifyContent="center">
            <Typography
              className="player-time-label"
              gutterBottom
              title={`switch to ${showRelativeTime ? 'H:m:s' : 'relative time'}`}
              onClick={() => setShowRelativeTime(( prev ) => !prev)}
              style={{
                cursor: 'pointer',
                margin: '0'
              }}
            >
              {currentTime ? getRealTime(currentTime) : '--:--:--'}
            </Typography>
          </Box>

          <Box minWidth="180px" display="flex" gridGap="4px" alignItems="center" justifyContent="flex-end" mr="2rem">
            <HeaderPopover
              buttonId="player-speed"
              className="player-speed-popover"
              icon={playerSpeed === 0.5 ? <X05/>
                : playerSpeed === 1 ? <X1/>
                  : playerSpeed === 2.5 ? <X25/>
                    : <X5/>}
              popoverContent={[
                {
                  value: '0.5x',
                  label: '0.5x',
                  onClick: () => setPlayerSpeed(0.5),
                },
                {
                  value: '1x',
                  label: '1x',
                  onClick: () => setPlayerSpeed(1),
                },
                {
                  value: '2.5x',
                  label: '2.5x',
                  onClick: () => setPlayerSpeed(2.5),
                },
                {
                  value: '5x',
                  label: '5x',
                  onClick: () => setPlayerSpeed(5),
                },
              ]}

            />
            <BaseButton isIconButton noMargins>
              <FullscreenIcon onClick={handleFullscreenClick}/>
            </BaseButton>
          </Box>
        </Box>

        <Box width="80px" ml="auto" alignItems="center" className="player-time-label">
          <Typography
            title={`switch to ${showRelativeTime ? 'H:m:s' : 'relative time'}`}
            gutterBottom
            onClick={() => setShowRelativeTime(( prev ) => !prev)}
            style={{
              cursor: 'pointer',
              margin: '0'
            }}
          >
            {endTimestamp ? getRealTime(endTime) : '--:--:--'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayerControls;
