import { AppContainer, setConfig } from 'react-hot-loader';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import Authenticator from 'Authenticator';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CLIENT_SETTINGS from 'lib/client_settings';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import Login from 'Login';
import { uniq } from 'ramda';
import { LicenseInfo } from '@material-ui/x-grid';
import * as HighCharts from 'highcharts';
import HighchartsStock from 'highcharts/highstock';
import variwide from 'highcharts/modules/variwide.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import parallelCoordinates from 'highcharts/modules/parallel-coordinates.js';
import './lib/i18n';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { onError } from '@apollo/client/link/error';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import * as localforage from 'localforage';
import { Helmet } from 'react-helmet';
import { setContext } from '@apollo/client/link/context';
import { theme } from 'theme';
import boost from 'highcharts/modules/boost';
import { APP_STATES, AppState } from './lib/global';
import MainLoader from './components/layout/MainLoader';

highchartsMore(HighCharts);
solidGauge(HighCharts);
variwide(HighCharts);
parallelCoordinates(HighCharts);
boost(HighCharts);
boost(HighchartsStock);

HighCharts.AST.allowedAttributes.push('data-reactroot');
HighchartsStock.AST.allowedAttributes.push('data-reactroot');

export const highcharts = HighCharts;
export const highchartsStock = HighchartsStock;

LicenseInfo.setLicenseKey(
  '1366871752e1129e1fd5b3ce8a8e9dacT1JERVI6Mjc5NDYsRVhQSVJZPTE2NTk4MDAyNTIwMDAsS0VZVkVSU0lPTj0x',
);

const isDevelopment = process.env.NODE_ENV !== 'production';

export let client: ApolloClient<NormalizedCacheObject>;

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xlg: true;
    xl: true;
  }
}

declare module '@material-ui/core/Box' {
  interface BoxProps {
    ref?: React.MutableRefObject<HTMLElement>;
  }
}

export const googleAnalyticsId = CLIENT_SETTINGS.public.ga4_ID;

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<MainLoader />}>
        <AppContainer>
          <ApolloProvider client={client}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={theme}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  {
                    googleAnalyticsId
                    && (
                    <Helmet>
                      <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
                      <script>
                        {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsId}');`}
                      </script>
                    </Helmet>
                    )
                  }

                  <CssBaseline />
                  <Authenticator />
                </SnackbarProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
        </AppContainer>
      </Suspense>
    </React.StrictMode>,
    document.getElementById('react-root'),
  );
};

const mergeArraysByField = (array1: any[] | undefined, array2: any[] | undefined, field: string) => {
  const mergedMap = new Map();
  const mergeObjects = (obj1, obj2) => ({ ...obj1, ...obj2 });

  (array1 || []).forEach((obj) => {
    mergedMap.set(obj[field], { ...obj });
  });

  (array2 || []).forEach((obj) => {
    if (mergedMap.has(obj[field])) {
      const mergedObj = mergeObjects(mergedMap.get(obj[field]), obj);
      mergedMap.set(obj[field], mergedObj);
    } else {
      mergedMap.set(obj[field], { ...obj });
    }
  });

  return Array.from(mergedMap.values());
};

type TimestampRange = [number, number];

const mergeTimestampRanges = (existing: TimestampRange[], incoming: TimestampRange): TimestampRange[] => {
  const result: TimestampRange[] = [];
  let [incomingStart, incomingEnd] = incoming;
  let merged = false;

  for (const [start, end] of existing) {
    // Check if the incoming range intersects with the current existing range
    if (end < incomingStart || start > incomingEnd) {
      // No intersection
      result.push([start, end]);
    } else {
      // There is an intersection, update the incoming range to be merged
      incomingStart = Math.min(incomingStart, start);
      incomingEnd = Math.max(incomingEnd, end);
      merged = true;
    }
  }

  if (merged) {
    result.push([incomingStart, incomingEnd]);
  } else {
    result.push(incoming); // No intersection with any existing range
  }

  // Sort the result array to keep it ordered
  return result.sort((a, b) => a[0] - b[0]);
}

async function init() {
  if (CLIENT_SETTINGS.public.sentry.dsnUrl && !isDevelopment) {
    const sentryIntegrations: Integration[] = [
      Sentry.browserTracingIntegration(),
    ];

    if (CLIENT_SETTINGS.public.sentry?.enableReplay) {
      console.log('%cEnabling Sentry Reply', 'color: green')
      sentryIntegrations.push(Sentry.replayIntegration());
    }

    const sentryOptions: Sentry.BrowserOptions = {
      dsn: CLIENT_SETTINGS.public.sentry.dsnUrl,
      integrations: sentryIntegrations,
      environment: CLIENT_SETTINGS.public.gpexeGraphUrl
        .replace('https://', '')
        .replace('.gpexe.com/ui/v2/', ''),
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: CLIENT_SETTINGS.public.sentry.replaySessionRate,
      // This sets the sample rate at 10%. You may want to change it to 100%
      // while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: CLIENT_SETTINGS.public.sentry.replayErrorRate,
      // If you're not already sampling the entire session, change the sample
      // rate to 100% when sampling sessions where errors occur.
    };

    if (CLIENT_SETTINGS.public.sentry.cors) {
      sentryOptions.tracePropagationTargets = [CLIENT_SETTINGS.public.sentry.cors];
    }

    Sentry.init(sentryOptions);
  }

  const token = (await localStorage.getItem('exelio_token')) || undefined;

  // auth link to dynamically get the token
  const authLink = setContext(async (_, { headers }) => {
    const tk = await localStorage.getItem('exelio_token') || undefined;
    return {
      headers: {
        ...headers,
        Authorization: token ? `JWT ${tk}` : '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: CLIENT_SETTINGS.public.gpexeGraphUrl,
  });

  const responseLogger = new ApolloLink((operation, forward) => forward(operation).map((result) => {
    if (
      operation.getContext().response.status === 200
      && AppState.getValue() === APP_STATES.maintenance
    ) {
      AppState.setValue(APP_STATES.restoration);
      setTimeout(() => {
        AppState.setValue(APP_STATES.normal);
      }, 5000);
    }
    return result;
  }));

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          team: {
            keyArgs: ['id'],
            merge: true,
          },
        },
      },
      TeamTypeRes: {
        fields: {
          content: {
            merge(existing: any[], incoming: any[], {}) {
              return uniq((existing || []).concat(incoming || []));
            },
          },
        },
      },
      ClubType: {
        fields: {
          groundSet: {
            merge(existing: any[], incoming: any[], {}) {
              return uniq((existing || []).concat(incoming || []));
            },
          },
        },
      },
      DrillType: {
        keyFields: ['id', 'index', 'start', 'end'],
      },
      AthleteSessionType: {
        keyFields: ['id'], // @todo verificare che ci sia su tutte le query
        fields: {
          averageP: { merge: true },
          equivalentDistanceIndex: { merge: true },
          accelerationEvents: { merge: true },
          kpi: {
            merge(existing: any[], incoming: any[], {}) {
              return mergeArraysByField(existing, incoming, 'name');
            },
          },
        },
      },
      // @todo verificare
      ASProfileType: {
        keyFields: ['id'],
        merge: true,
      },
      GenericValueType: {
        keyFields: false,
      },
      GenericImuEventType: {
        keyFields: false,
      },
      PathType: {
        keyFields: false,
      },
      PowerEventPathType: {
        merge: true,
      },
      GenericSeriesType: {
        keyFields: ['id', 'lastUpdate', 'drill'],
        merge: true,
      },
      TeamSessionType: {
        keyFields: ['id', 'drill'],
        fields: {
          matchDistance: {
            merge: true,
          },
          drills: {
            merge: true,
          },
        },
      },
      ViewerData: {
        keyFields: ['id'],
        fields: {
          intervals: {
            merge(existing: [number, number][] = [], incoming: [number, number], opts) {
              console.log('intervals', existing, incoming, opts, mergeTimestampRanges(existing, [opts.variables.start, opts.variables.end]))
              return mergeTimestampRanges(existing, [opts.variables.start, opts.variables.end]);
            }
          },
          data: {
            merge(existing = [], incoming, opts) {
              const existingPathData = existing || [];
              const newPathData = incoming || [];

              console.log('data', existing, incoming, opts)

              const filteredPathData = newPathData
              .reduce((acc, pj) => {
                if (existingPathData.indexOf(pj) === -1) {
                  acc.push(pj)
                }

                return acc;
              }, [])

              return [
                ...existingPathData,
                ...filteredPathData,
              ].sort((a, b) => a - b);
            }
          },
        }
      }
    },
  });

  localforage.config({
    name: 'queryPersist',
  });
  await localforage.setDriver(localforage.INDEXEDDB);

  const retryLink = new RetryLink({
    delay: (count) => count * 1000 * Math.random(),
    attempts: {
      // non ritento se è un problema di validazione lato server
      retryIf: (error) => error.statusCode !== 400,
    },
  });
  /*
  const queueLink = new QueueLink({
    whitelistedOperations: [], // ['Me', 'pingExport'],
    queryLimit: 3,
  }); */

  await persistCache({
    cache,
    storage: new LocalForageWrapper(localforage),
    debug: isDevelopment,
    maxSize: 100_000_000,
  });

  client = new ApolloClient({
    devtools:{
      enabled: isDevelopment,
    },
    link: ApolloLink.from([
      onError(({
        graphQLErrors, networkError, operation, forward,
      }) => {
        if (networkError) {
          const context = operation.getContext();
          console.error(`[Network error]: ${networkError}`, context.response?.status || 'no response');
          if (context.response?.status === 503) {
            AppState.setValue(APP_STATES.maintenance);
          }

          // tento retry su problemi di rete
          console.log('Retry on Network Error...');
          return forward(operation);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => console.error(
            `[GraphQL error]: Message: ${message}, Path: ${path}`,
            locations,
            message,
          ));

          // tento retry su problemi di GraphQL
          console.log('Retry on GraphQL Error...');
          return forward(operation);
        }
      }),
      // queueLink,
      retryLink,
      responseLogger,
      authLink,
      httpLink,
    ]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  if (!token) {
    return ReactDOM.render(
      <Suspense fallback={<MainLoader />}>
        <MuiThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <CssBaseline />
              <Login />
            </SnackbarProvider>
          </ApolloProvider>
        </MuiThemeProvider>
      </Suspense>,
      document.getElementById('react-root'),
    );
  }

  render();
  return 0;
}

init().then(render).catch((error) => console.error('Initialization failed', error));
/*
if (process.env.NODE_ENV !== 'production') {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
} */

// *** React hot loader
setConfig({ pureRender: false });
if (module.hot) {
  module.hot.accept(() => {});
}
