import { PlayerDataIntervals } from '../../types/webPlayer';

/**
 * FpsCtrl is a utility class for controlling a callback function to run at a specific frame per second rate.
 * It provides methods to start, pause, and step through frames at the designated FPS rate.
 */
export class FpsCtrl {
  isPlaying = false;
  time: number | null = null;
  frame = -1;
  tref: number | undefined = undefined;
  callback: undefined | Function = undefined;
  delay = 1000;
  fps = 25;
  multiplier = 1;

  constructor(fps: number, callback: Function, multiplier: number = 1) {
    this.delay = 1000 / fps
    this.fps = fps;
    this.callback = callback;
    this.multiplier = multiplier;
  }

  loop = ( timestamp: number) => {
    if (this.time === null) {
      this.time = timestamp;
    }

    const seg = Math.floor((timestamp - this.time) / this.delay);
    if (seg > this.frame) {
      this.frame = seg;

      if (this.callback) {
        this.callback({
          frame: this.frame * this.multiplier,
        })
      }
    }

    this.tref = requestAnimationFrame(this.loop)
  };

  setMultiplier = (value: number) => {
    this.multiplier = value;
  }

  frameRate = ( newfps: number ) => {
    if (!newfps) {
      return this.fps;
    }

    this.fps = newfps;
    this.delay = 1000 / this.fps;
    this.frame = -1;
    this.time = null;
  };

  start = () => {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.tref = requestAnimationFrame(this.loop);
    }
  };

  stepForward = (seconds: number) => {
    if (this.isPlaying) {
      this.pause();
    }
    this.frame += this.fps;
    this.time = (this.time || 0) + 1000 * seconds;

    if (this.callback) {
      this.callback({
        time: this.time,
        frame: this.frame
      })
    }
  };

  stepBackward = (seconds: number) => {
    if (this.isPlaying) {
      this.pause();
    }
    this.frame -= this.fps;
    this.time = (this.time || 0) - 1000 * seconds;

    if (this.callback) {
      this.callback({
        time: this.time,
        frame: this.frame
      })
    }
  };

  pause = () => {
    if (this.isPlaying) {
      if (this.tref) {
        cancelAnimationFrame(this.tref);
      }

      this.isPlaying = false;
      this.time = null;
      this.frame = -1;
    }
  };
}

/**
 * Checks if the current time is near the end of any interval within a specified threshold.
 *
 * @param {number} curTime - The current time in milliseconds.
 * @param {PlayerDataIntervals} dataIntervals - An array of time intervals represented as pairs of start and end times.
 * @param {number} [threshold=20000] - The threshold in milliseconds to consider as "near the end" of an interval. Default is 20,000 milliseconds.
 * @param {Function} [cb] - Optional callback function to be invoked if the current time is near the end of an interval.
 *
 * @returns {void}
 */
export const checkNearEndOfInterval = (
  curTime: number,
  dataIntervals: PlayerDataIntervals,
  threshold: number = 60000,
  cb?: Function
): void => {
  const isNearEnd = dataIntervals.find(interval => {
    if (!interval[1]) return false;
    return Math.abs(interval[1] - curTime) <= threshold;
  });

  if (isNearEnd) {
    if (cb) {
      cb(isNearEnd);
    }
  }
};

export const enterFullscreen = (elem: null | HTMLDivElement) => {
  if (elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else {
      // @ts-ignore
      if (elem.mozRequestFullScreen) { // Firefox
        // @ts-ignore
        elem.mozRequestFullScreen();
        // @ts-ignore
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        // @ts-ignore
        elem.webkitRequestFullscreen();
      }
    }
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.mozCancelFullScreen) {
    // @ts-ignore
    document.mozCancelFullScreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    // @ts-ignore
    document.webkitExitFullscreen();
  }
};
