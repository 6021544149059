import React, {
  Dispatch, SetStateAction, useState, useEffect,
} from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import ExportIcon from '@images/IconHeader/export.svg';
import { format as dateFormat } from 'date-fns';
import HeaderPopover from '../layout/HeaderPopover';
import { CREATE_OUTCOMING_FILE, EXPORT_DATETIME_FORMAT } from '../../mutation/export';
import { SNACKBAR_TIMEOUT } from '../constants';

export type DialogExportProps = {
  setDialogInfo: Dispatch<SetStateAction<{ start: boolean; available: boolean; data?: object; format: string; }>>,
  objectId?: string,
  templateId?: string,
}

type ExportDataType = {
  format: 'pdf' | 'csv' | 'xlsx' | 'xml' | 'txt' | 'raw',
  label?: string,
  data?: object,
  dialogFormat?: 'standard' | 'rwp' | 'split',
  DialogComponent?: React.FC<DialogExportProps>,
  downloadUrl?: string,
  storedOnS3?: boolean,

  customTarget?: string,
}

type PropsIn = {
  teamId: string,
  templateId: string,
  disable?: boolean,
  target: 'AthleteSession' | 'AthleteSessionList' | 'TeamSession' | 'IncomingFile' | 'Track' | 'ASProfile' | 'MPProfile'
    | 'ASProfileList' | 'MPProfileList' | 'ASProfileComparison' | 'MPProfileComparison' | 'Trend' | 'Summary' | 'Radar' | 'RPEList',
  exportData: ExportDataType[],
  filename?: string,
  enableDialog?: boolean,
  collapseOnSmallDesktop?: boolean,
  collapseOnMobile?: boolean,
  disabledExportMenuItems?: object,
  buttonSize?: 'sm' | 'md' | 'lg',
  tooltipText?:string,
}

export function ExportButton(props: PropsIn) {
  const {
    teamId,
    templateId,
    target,
    exportData,
    disable,
    filename,
    enableDialog,
    collapseOnSmallDesktop,
    collapseOnMobile,
    disabledExportMenuItems,
    buttonSize,
    tooltipText,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogInfo, setDialogInfo] = useState({
    start: false,
    available: false,
    format: '',
  });

  const [dialogType, setDialogType] = useState('');

  const handleDialogAction = (format: ExportDataType['format'], data: object, dialog: ExportDataType['dialogFormat']) => {
    const sessionDialog = target === 'TeamSession' && (['rwp', 'split'].includes(dialog) || enableDialog);
    if (sessionDialog || enableDialog) {
      setDialogInfo({
        start: false,
        available: true,
        format,
        data,
      });
      setDialogType(dialog);
    } else {
      doExport(format, data, dialog);
    }
  };

  const [createExport] = useMutation(CREATE_OUTCOMING_FILE, {
    onCompleted: (data) => {
      enqueueSnackbar(`${t('exports.creating', 'creating')} ${data.res.filename}`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      if (dialogInfo.available) {
        setDialogInfo((prev) => ({
          ...prev,
          available: false,
          start: false,
        }));
      }
    },
    onError: () => {
      enqueueSnackbar(t('exports.dialog.exportError', 'error during request for document'), {
        variant: 'error',
        autoHideDuration: SNACKBAR_TIMEOUT,
      });
      if (dialogInfo.available) {
        setDialogInfo((prev) => ({
          ...prev,
          available: false,
          start: false,
        }));
      }
    },
  });

  const doExport = (exportFormat: ExportDataType['format'], data: object, label?: string) => {
    const teamSessionTarget = label === `${t('track.widgets.labels.events', 'events')}` ? 'TeamSessionEvents'
      : label === 'rwp' ? 'TeamSessionRollingWindowPeaks'
        : label === 'split' ? 'TeamSessionSplitted' : target;

    const trackTarget = label === 'raw' ? 'Gpexe' : target;

    const ASProfileListTarget = label === 'extended' ? 'ASProfileExtList' : target;

    createExport({
      variables: {
        filename: filename ? `${filename}.${exportFormat}`
          : `${target}_${dateFormat(new Date(), EXPORT_DATETIME_FORMAT)}.${exportFormat}`,
        target: target === 'TeamSession' ? teamSessionTarget : target === 'Track' ? trackTarget : target === 'ASProfileList' ? ASProfileListTarget : target,
        teamId,
        templateId,
        format: exportFormat,
        data: JSON.stringify(data),
      },
    });
  };

  useEffect(() => {
    if (dialogInfo.start) {
      doExport(dialogInfo.format, dialogInfo.data, dialogType);
    }
  }, [dialogInfo.start]);

  return (
    <>
      <HeaderPopover
        buttonId="export-button"
        className="export-button"
        key="export-button"
        icon={<ExportIcon />}
        tooltip={tooltipText}
        disabled={disable}
        label={t('header.action.export', 'export')}
        collapseOnSmallDesktop={collapseOnSmallDesktop}
        collapseOnMobile={collapseOnMobile}
        disabledExportMenuItems={disabledExportMenuItems}
        popoverContent={exportData.map((exp) => ({
          key: exp.format,
          value: exp.format,
          label: exp.label || t(`exports.type.${exp.format}`, `${exp.format}`).toUpperCase(),
          onClick:
           (enableDialog || target === 'TeamSession') && exp.dialogFormat
             ? () => handleDialogAction(exp.format, exp.data, exp.dialogFormat)
             : () => doExport(exp.format, exp.data, exp.label?.split(' ')[0]),
        }))}
        buttonSize={buttonSize}
      />
      { dialogInfo.available
        && exportData.map(({
          DialogComponent, dialogFormat, data, format,
        }) => {
          if (DialogComponent && dialogFormat === dialogType && format === dialogInfo.format) {
            return (
              <DialogComponent
                key={data?.id}
                setDialogInfo={setDialogInfo}
                objectId={data?.id}
                templateId={templateId}
              />
            );
          }
        })}
    </>
  );
}
