import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@images/icons/svg/visibility.svg';
import NoVisibilityIcon from '@images/icons/svg/noVisibility.svg';
import ColorPicker from 'components/form/ColorPicker';
import { Loader } from 'lib/icons';
import { PlayersTableProps } from 'types/webPlayer';
import InfoIcon from '@material-ui/icons/Info';
import { DoubleListIDs, List } from '../form/DualSortableList';
import { PlayersTeamsContext } from './WebPlayer';
import { closestCenter, DndContext, DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FavoriteIcon from '@material-ui/icons/Favorite';

const style = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '0.25rem',
  paddingLeft: '0.5rem'
}

export const teamColors = {
  teamA: '#b51102',
  teamB: '#336699',
}

const PlayersTable = ( props: PlayersTableProps ) => {
  const {
    athleteSessions,
    enabledPlayers,
    infoPlayers,
    loadingPlayers,
    playersColors,
    playersExtraData,
    playersDetails,
    setEnabledPlayers,
    setInfoPlayers,
    setPlayersColors,
  } = props;

  const {playersTeams, setPlayersTeams} = React.useContext(PlayersTeamsContext);

  const handlePlayersEnable = ( checked: boolean, athId: string ) => {
    const newSet = [...enabledPlayers];
    if (checked && !newSet.includes(athId)) {
      newSet.push(athId);
    } else if (!checked && newSet.includes(athId)) {
      newSet.splice(newSet.indexOf(athId), 1);
    }

    setEnabledPlayers(newSet);
  };

  const handleSetInfoPlayers = useCallback(( id: string ) => {
    if (infoPlayers.includes(id)) {
      setInfoPlayers(( prevState ) => prevState.filter(( i ) => i !== id));
    } else {
      setInfoPlayers(( prevState ) => [...prevState, id]);
    }
  }, [setInfoPlayers, infoPlayers])

  const [itemsList, setItemsList] = useState<DoubleListIDs>({});

  const sortableRows = athleteSessions.length
    ? athleteSessions.map(( athlete ) => {
      const name = athlete?.athlete?.name || '';
      const number = playersDetails[athlete.id] && playersDetails[athlete.id].number || '';
      const shortName = athlete?.athlete?.shortName || '';

      const isEnabled = enabledPlayers.includes(athlete.id);
      const isLoading = loadingPlayers.includes(athlete.id);

      return {
        id: athlete.id,
        component: (<div style={style}>
          <div className="athlete-visibility">{(
            isLoading
              ? <Loader className="players-loading"/>
              : (
                <Box onClick={() => handlePlayersEnable(!isEnabled, athlete.id)}>
                  {isEnabled ? <VisibilityIcon/> : <NoVisibilityIcon/>}
                </Box>
              )
          )}</div>
          <div className="athlete-info">{(
            isLoading
              ? null
              : (
                <Box onClick={() => handleSetInfoPlayers(athlete.id)}>
                  <InfoIcon opacity={infoPlayers.includes(athlete.id) ? 1 : 0.5}/>
                </Box>
              )
          )}</div>
          <div className="athlete-color">
            <ColorPicker
              width="23px"
              height="23px"
              onChange={( color ) => setPlayersColors(( prevState ) => ({
                ...prevState,
                [athlete.id]: color,
              }))}
              defaultColor={
              playersColors[athlete.id]
                ? playersColors[athlete.id]
                : playersTeams.listA && playersTeams.listA.includes(athlete.id)
                  ? teamColors.teamA
                  : teamColors.teamB
              }
              disableGradientPicker
              showDefaultColors
            />
          </div>
          <div className="athlete-name">
            <Tooltip arrow placement="right" title={`${name}`}>
            <span className="nolink-col-left">
              {number ? `${number} - ${shortName}` : `${shortName}`}
            </span>
            </Tooltip>
          </div>
          <div className="athlete-extra-data__s" title="speed">{playersExtraData[athlete.id] && playersExtraData[athlete.id].s?.toFixed(2)} <DirectionsRunIcon /></div>
          <div className="athlete-extra-data__hr" title="hearth rate">{playersExtraData[athlete.id] && playersExtraData[athlete.id].hr?.toFixed(0)} <FavoriteIcon /></div>
        </div>)
      }
    })
    : [];

  const dragEndHandler = useCallback(( e: DragEndEvent ) => {
    // Check if item is drag into unknown area
    if (!e.over || !e.active.data.current || !e.over.data.current) return;

    // Check if item position is the same
    if (e.active.id === e.over.id) return;

    // Check if item is moved outside of the column
    if (
      e.active.data.current.sortable.containerId !==
      e.over.data.current.sortable.containerId
    )
      return;

    // Sort the items list order based on item target position
    const containerName = e.active.data.current.sortable.containerId;

    setItemsList(( itemsList ) => {
      const temp = {...itemsList};
      if (!e.over) return temp;
      const oldIdx = temp[containerName].items.findIndex(( id ) => id === e.active.id.toString());
      const newIdx = temp[containerName].items.findIndex(( id ) => id === e.over!.id.toString());
      temp[containerName].items = arrayMove(temp[containerName].items, oldIdx, newIdx);
      return temp;
    });
  }, []);

  const dragOverHandler = useCallback(( e: DragOverEvent ) => {
    // Check if item is drag into unknown area
    if (!e.over) return;
    // Get the initial and target sortable list name
    const initialContainer = e.active.data.current?.sortable?.containerId;
    const targetContainer = e.over.data.current?.sortable?.containerId;

    // if there are none initial sortable list name, then item is not sortable item
    if (!initialContainer) return;

    // Order the item list based on target item position
    setItemsList(( itemsList ) => {
      const temp = {...itemsList};

      // If there are no target container then item is moved into a droppable zone
      // droppable = whole area of the sortable list (works when the sortable list is empty)

      if (!targetContainer) {
        // If item is already there then don't re-added it
        if (itemsList[e.over!.id].items.find(( id ) => id === e.active.id.toString())) return temp;

        const elToMove = temp[initialContainer].items.find(( id ) => id === e.active.id.toString());

        // Add item to it's target container which the droppable zone belongs to
        if (elToMove) {
          // Remove item from it's initial container
          temp[initialContainer].items = temp[initialContainer].items.filter(( id ) => id !== e.active.id.toString());
          temp[e.over!.id].items.push(elToMove);
        }

        return temp;
      }

      // If the item is drag around in the same container then just reorder the list
      if (initialContainer === targetContainer) {
        const oldIdx = temp[initialContainer].items.findIndex(( id ) => id === e.active.id.toString());
        const newIdx = temp[initialContainer].items.findIndex(( id ) => id === e.over!.id.toString());
        temp[initialContainer].items = arrayMove(
          temp[initialContainer].items,
          oldIdx,
          newIdx
        );
      } else {
        // If the item is drag into another different container
        const elToMove = temp[initialContainer].items.find(( id ) => id === e.active.id.toString());

        if (elToMove) {
          // Remove item from it's initial container
          temp[initialContainer].items = temp[initialContainer].items.filter(( id ) => id !== e.active.id.toString());

          // Add item to it's target container
          const newIdx = temp[targetContainer].items.findIndex(( id ) => id === e.over!.id.toString());

          temp[targetContainer].items.splice(newIdx, 0, elToMove);
        }
      }

      return temp;
    });
  }, []);

  useEffect(() => {
    setPlayersTeams(Object.keys(itemsList).reduce(( ac, k ) => {
      return {
        ...ac,
        [k]: itemsList[k].items
      }
    }, {}))
  }, [itemsList]);

  useEffect(() => {
    if (Object.keys(itemsList).length === 0 && sortableRows.length > 0) {
      setItemsList({
        listA: {title: 'Team A', items: sortableRows.map(( row ) => row.id.toString())},
        listB: {title: 'Team B', items: []}
      })
    }
  }, [itemsList, sortableRows]);

  return (
    <Box p={2} marginLeft="-20px" className="webplayer__players-table">
      {
        sortableRows.length > 0
        && <DndContext
          onDragEnd={dragEndHandler}
          onDragOver={dragOverHandler}
          collisionDetection={closestCenter}
        >
          <section
            className="dsl__container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: '4rem',
              padding: '1rem',
            }}
          >
            {Object.keys(itemsList).map(( key ) => (
              <List
                id={key}
                title={itemsList[key].title}
                // @ts-ignore errata valutazione TS
                items={
                  itemsList[key].items
                    .map(( id ) => sortableRows
                      .find(( row ) => row.id.toString() === id)).filter(n => n !== undefined)}
              />
            ))}
          </section>
        </DndContext>
      }
    </Box>
  );
};

export default PlayersTable;
