import React, {
  useState, useContext, useMemo, useRef, useEffect,
} from 'react';
import { CursorContext } from '../track/TrackChartContainer';
import { useQueryCached } from '../utils/graphql';
import { GroundRes, GroundVars, GROUND_DETAILS } from '../../query/grounds';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import Error from '../layout/Error';
import GroundField from './GroundField';
import { getPlayersLines, getPlayersPointsLPS, getSizesFromRef } from '../utils/groundNew';
import { GroundType } from '../../models/ground';
import { useDebouncedResize } from '../../lib/dom';
import { LPSGroundProps } from 'types/webPlayer';
import { computeAngle, flipPoint, rotate } from '../track/ground/utils';

function getScale(
  width: number,
  height: number,
  winWidth: number,
  winHeight: number,
) {
  const scaleX = winWidth / width;
  const scaleY = winHeight / height;
  return Math.min(scaleX, scaleY);
}

const GroundLPS = (props: LPSGroundProps) => {
  const {
    activePlayer,
    hoverPlayer,
    defaultPlayersColor,
    enabledPlayers,
    groundId,
    infoPlayers,
    jerseyOrNumber,
    onPlayerClick,
    onPlayerEnter,
    onPlayerLeave,
    playerLinks,
    playersColors,
    playersDetails,
    removeLink,
    series,
    showGroundName,
    showVertices,
    trailsEnabled,
    userUnits,
  } = props;

  const parentRef = useRef<HTMLDivElement | null>(null);
  const [zoom, setZoom] = useState(1);
  const [lpsGroundData, setLpsGroundData] = useState<GroundType | undefined>(undefined);
  const [scale, setScale] = useState(1);
  const { cursor } = useContext(CursorContext);
  const [windowSize, setWindowSize] = useState({
    WINDOW_WIDTH: 0,
    WINDOW_HEIGHT: 0,
  })
  const [vertex, setVertex] = useState({
    vertexA: {
      x: 0,
      y: 0,
    },
    vertexC: {
      x: 0,
      y: 0,
    },
  });

  useEffect(() => {
    setWindowSize(getSizesFromRef(parentRef))
  }, []);

  useDebouncedResize(() => {
    setWindowSize(getSizesFromRef(parentRef))
  }, 200);

  const {
    WINDOW_WIDTH, WINDOW_HEIGHT
  } = windowSize;

  const {
    error: errorGround,
  } = useQueryCached<GroundRes, GroundVars>(GROUND_DETAILS, {
    variables: {
      id: groundId,
    },
    skip: groundId == null,
    ...CACHE_AND_NETWORK,
    onCompleted: (response) => {
      let vertexA = {
        x: response?.res.vertexAX?.value || 0,
        y: response?.res.vertexAY?.value || 0,
      };
      let vertexC = {
        x: response?.res.vertexCX?.value || 0,
        y: response?.res.vertexCY?.value || 0,
      };

      // verifico se sono invertiti x e y
      if (vertexA.x && vertexC.x && vertexA.x > vertexC.x) {
        const swap = vertexC;
        vertexC = vertexA;
        vertexA = swap;
      }

      if (vertexA.y && vertexC.y && vertexA.y < vertexC.y) {
        const swap = vertexC.y;
        vertexC.y = vertexA.y;
        vertexA.y = swap;
      }

      // calcolo le dimensioni del campo (dai dati)
      const width = vertexC.x && vertexA.x ? (vertexC.x - vertexA.x) : 1;
      const height = vertexA.y && vertexC.y ? (vertexA.y - vertexC.y) : 1;

      // calcolo il valore della scala per adattare il campo alla canvas
      const scaleValue = getScale(
        width + 10,
        height + 10,
        WINDOW_WIDTH,
        WINDOW_HEIGHT,
      );

      setScale(scaleValue);

      // calcolo le nuove dimensioni del campo scalate
      vertexA = {
        x:
          rotate(
            flipPoint({ // serve per verificare qual è il lato più lungo
              x: response.res.vertexAX?.value,
              y: response.res.vertexAY?.value,
            }, response?.res),
            computeAngle(response?.res),
          ).x * scaleValue,
        y:
          rotate(
            flipPoint({
              x: response.res.vertexAX?.value,
              y: response.res.vertexAY?.value,
            }, response?.res),
            computeAngle(response?.res),
          ).y * scaleValue,
      };
      vertexC = {
        x:
          rotate(
            flipPoint({
              x: response.res.vertexCX?.value,
              y: response.res.vertexCY?.value,
            }, response?.res),
            computeAngle(response?.res),
          ).x * scaleValue,
        y:
          rotate(
            flipPoint({
              x: response.res.vertexCX?.value,
              y: response.res.vertexCY?.value,
            }, response?.res),
            computeAngle(response?.res),
          ).y * scaleValue,
      };

      setScale(scaleValue);

      // sovrascrivo i dati locali nei dettagli del ground da passare al Pitch
      setLpsGroundData(response.res);

      setVertex({
        vertexA,
        vertexC,
      });
    },
  });

  const scaledWidth = vertex.vertexC.x && vertex.vertexA.x ? vertex.vertexC.x - vertex.vertexA.x : 1;
  const scaledHeight = vertex.vertexA.y && vertex.vertexC.y ? vertex.vertexA.y - vertex.vertexC.y : 1;

  const offX = vertex.vertexA.x - (WINDOW_WIDTH - scaledWidth) / 2;
  const offY = vertex.vertexC.y - (WINDOW_HEIGHT - scaledHeight) / 2;


  const playersPoints = useMemo(() => getPlayersPointsLPS({
    cursor,
    enabledPlayers,
    scale,
    series,
  }), [series, cursor, enabledPlayers, scale]);

  const playersLines = useMemo(() => getPlayersLines({
    playersPoints,
    playerLinks,
  }), [playerLinks, playersPoints]);

  if (errorGround) return <Error />;

  return (
    <>
      {showGroundName
        && (
          <div>
            {lpsGroundData?.id}
            {' '}
            -
            {lpsGroundData?.name}
          </div>
        )}

      <GroundField
        activePlayer={activePlayer}
        hoverPlayer={hoverPlayer}
        cursor={cursor}
        dataGround={lpsGroundData}
        defaultPlayersColor={defaultPlayersColor}
        enabledPlayers={enabledPlayers}
        infoPlayers={infoPlayers}
        jerseyOrNumber={jerseyOrNumber}
        onPlayerClick={onPlayerClick}
        onPlayerEnter={onPlayerEnter}
        onPlayerLeave={onPlayerLeave}
        playersColors={playersColors}
        playersDetails={playersDetails}
        playersLines={playersLines}
        removeLink={removeLink}
        series={series}
        setZoom={setZoom}
        showVertices={!!showVertices}
        trailsEnabled={trailsEnabled}
        offX={offX}
        offY={offY}
        scale={scale}
        ref={parentRef}
        WINDOW_WIDTH={WINDOW_WIDTH}
        WINDOW_HEIGHT={WINDOW_HEIGHT}
        userUnits={userUnits}
        zoom={zoom}
      />
    </>
  );
};

export default GroundLPS;
