import {
  format, getDaysInMonth, lastDayOfMonth, lastDayOfWeek, subDays, subMonths,
  subWeeks,
} from 'date-fns';
import React, { useState, useEffect } from 'react';
import { withMe } from 'components/MeUserWrapper';
import { MeType } from 'models/me';
import { OptionType } from 'components/Autocomplete';
import { FieldsGroup } from 'components/form/FieldsGroup';
import { SelectField } from 'components/form/SelectField';
import { TextField } from 'components/form/TextField';
import { DATE_FORMAT } from 'components/constants';
import { Row } from 'components/layout/Row';
import { Column } from 'components/layout/Column';
import DatePicker from 'components/form/DatePicker';
import { useTranslation } from 'react-i18next';
import { KpiListSelector, MatchSelector } from 'components/stats/Filters';
import { rangeOptions, newDefaultDate, dateToUtc } from 'components/utils/utils';
import {
  CategoryListSelector, TagListSelector, AthletesListSelector,
} from './Filters';
import { matchDistanceOptions, matchCycleOptions } from './TrendView';
import { isDate } from '../utils/utils';

export type myStatsType = {
  refetchMyStatsStatus: number,
  statsSessionId: string,
  statsSessionRowsIds: string,
  statsSessionType: string,
  statsSessionsData: object[],
}

export const defaultMyStats = {
  refetchMyStatsStatus: 0,
  statsSessionId: '',
  statsSessionRowsIds: '',
  statsSessionType: '',
  statsSessionsData: [],
};

export type statsProperties = {
  title: string | undefined;
  showMyStats: boolean;
  showDashboard: boolean;
}

export type AthleteOptions = {
  id: string,
  value: string,
  groupBy: string,
  startDate: string,
  endDate: string,
}
export type CategoryOptions = {
  id: string,
  color: string,
  value:string,
}
export type KpiOptions = {
  id: string,
  groupBy: string,
  value: string,
}
export type TagOptions = {
  id: string,
  value: string,
}

export type SeriesTrendState = {
  period: string | null;
  startDate: Date | null;
  endDate: Date | null;
  KPI: OptionType[] | null;
  athletes: { id: string; value: string }[] | null;
  categories: CategoryOptions[] | null;
  groupingBy: string | null;
  tags: TagOptions[] | null;
  matchDistance: [{id:number, value: number}] | null,
  matchCycle: [{id:number, value: number}] | null,
  chartType: string | null;
  statSessionRowId: string,
};

type PropsIn = {
  currentValues: SeriesTrendState;
  setCurrentValues: (series: SeriesTrendState) => void;
  addSeries?: boolean;
  statsType?: string;
  allowAthleteMultivalue: boolean;
  chartType?: string;
  barLine?: boolean;
  defaultValueSelected?: boolean;
  setdisabledButton: (b: boolean) => void;
  athleteOptions: AthleteOptions[],
  categoryOptions: CategoryOptions[],
  kpiOptions: KpiOptions[],
  tagOptions: TagOptions[],
};
function SeriesInputs(props: PropsIn & { me: MeType }) {
  const {
    currentValues,
    setCurrentValues,
    addSeries,
    statsType,
    allowAthleteMultivalue,
    barLine,
    defaultValueSelected,
    setdisabledButton,
    athleteOptions,
    categoryOptions,
    tagOptions,
    kpiOptions,
  } = props;
  const { t } = useTranslation();
  const [isValidCustomPeriod, setIsValidCustomPeriod] = useState(true);

  const checkDisabledAddSeries = (s: SeriesTrendState) => {
    if (
      (s.period === 'custom' && (!s.startDate || !s.endDate))
      || !s.KPI
      || s.KPI?.length === 0
      || !s.categories
      || (s.categories && s.categories.length === 0)
      || !s.athletes
      || s.athletes?.length === 0
      || !isValidCustomPeriod
    ) {
      return true;
    }
    return false;
  };

  function getDatesForLastNWeeks(nWeeks) {
    // last 4 weeks = last 3 weeks + current week
    // last 8 weeks = last 7 weeks + current week
    let today = new Date();
    // calculate the day of the week, shifting to make monday = 0 and sunday = 6
    let dayOfWeek = (today.getDay() + 6) % 7;
    // calculate the sunday of the current week
    let sundayOfCurrentWeek = new Date(today);
    sundayOfCurrentWeek.setDate(today.getDate() + (7 - dayOfWeek - 1));
    // calculate the monday `nWeeks` ago
    let daysSinceMonday = dayOfWeek + 7 * (nWeeks - 1);
    let mondayOfNWeeksBefore = new Date(today);
    mondayOfNWeeksBefore.setDate(today.getDate() - daysSinceMonday);
    return {
        startDate: dateToUtc(mondayOfNWeeksBefore),
        endDate: dateToUtc(sundayOfCurrentWeek)
    };
}

  const getStartDate = (period: string | null) => {
    if (period === 'custom') return null;
    if (period === 'all_season') return dateToUtc(new Date(props?.me?.lastTeam?.startDate));
    if (period === 'current_week') {
      return dateToUtc(subDays(lastDayOfWeek(new Date(), { weekStartsOn: 1 }), 6));
    }
    if (period === 'previous_week') {
      return dateToUtc(subDays(lastDayOfWeek(new Date(), { weekStartsOn: 1 }), 13));
    }
    if (period === 'current_month') {
      return dateToUtc(subDays(lastDayOfMonth(new Date()), getDaysInMonth(new Date()) - 1));
    }
    if (period === 'previous_month') {
      // tslint:disable-next-line: max-line-length
      return dateToUtc(subDays(
        lastDayOfMonth(subMonths(new Date(), 1)),
        getDaysInMonth(lastDayOfMonth(subMonths(new Date(), 1))) - 1,
      ));
    }
    if (period === 'last_7_days') {
      return dateToUtc(subDays(new Date(), 6));
    }
    if (period === 'last_30_days') {
      return dateToUtc(subDays(new Date(), 29));
    }
    if (period === 'last_4_weeks') {
      return getDatesForLastNWeeks(4).startDate;
    }
    if (period === 'last_8_weeks') {
      return getDatesForLastNWeeks(8).startDate;
    }
    return dateToUtc(new Date());
  };

  const getEndDate = (period: string | null) => {
    if (period === 'custom') return null;
    if (period === 'all_season') return dateToUtc(new Date(props?.me?.lastTeam?.endDate));
    if (period === 'current_week') {
      return dateToUtc(lastDayOfWeek(new Date(), { weekStartsOn: 1 }));
    }
    if (period === 'previous_week') {
      return dateToUtc(subDays(lastDayOfWeek(new Date(), { weekStartsOn: 1 }), 7));
    }
    if (period === 'current_month') {
      return dateToUtc(lastDayOfMonth(new Date()));
    }
    if (period === 'previous_month') {
      return dateToUtc(lastDayOfMonth(subMonths(new Date(), 1)));
    }
    if (period === 'last_4_weeks') {
      return getDatesForLastNWeeks(4).endDate;
    }
    if (period === 'last_8_weeks') {
      return getDatesForLastNWeeks(8).endDate;
    }
    return dateToUtc(new Date());
  };
  const series = currentValues;

  const disabledAddSeries = checkDisabledAddSeries(series);

  useEffect(() => {
    setdisabledButton(disabledAddSeries);
  }, [disabledAddSeries]);

  const periodHardcoded = [
    { label: t('stats.drawer.periodOption.allSeason', 'all season'), value: 'all_season' },
    { label: t('stats.drawer.periodOption.currentWeek', 'current week'), value: 'current_week' },
    { label: t('stats.drawer.periodOption.previousWeek', 'previous week'), value: 'previous_week' },
    { label: t('stats.drawer.periodOption.currentMonth', 'current month'), value: 'current_month' },
    { label: t('stats.drawer.periodOption.previousMonth', 'previous month'), value: 'previous_month' },
    { label: t('stats.drawer.periodOption.lastSevenDays', 'last 7 days'), value: 'last_7_days' },
    { label: t('stats.drawer.periodOption.lastThirtyDays', 'last 30 days'), value: 'last_30_days' },
    { label: t('stats.drawer.periodOption.lastFourWeeks', 'last 4 weeks'), value: 'last_4_weeks' },
    { label: t('stats.drawer.periodOption.lastEightWeeks', 'last 8 weeks'), value: 'last_8_weeks' },
    { label: t('stats.drawer.periodOption.custom', 'custom'), value: 'custom' },
  ];

  const groupByHardcoded = [
    {
      label: t('stats.drawer.groupByOption.noGrouping', 'no grouping'),
      value: 'no grouping',
      disabled: new Date(series?.startDate).getTime() !== new Date(series?.endDate).getTime(),
    },
    { label: t('stats.drawer.groupByOption.session', 'session'), value: 'session' },
    { label: t('stats.drawer.groupByOption.day', 'day'), value: 'day' },
    { label: t('stats.drawer.groupByOption.week', 'week'), value: 'week' },
    { label: t('stats.drawer.groupByOption.month', 'month'), value: 'month' },
  ];

  useEffect(() => {
    if (series?.groupingBy === 'no grouping' && series.startDate !== series.endDate) {
      setCurrentValues({ ...series, groupingBy: 'session' });
    }
  }, [series?.startDate, series?.endDate]);

  const customPeriodErrorText = t('stats.dialog.groupingByInvalidRange', 'the selected range is smaller than the chosen grouping by period.');

  useEffect(() => {
    if (
      statsType === 'trend'
      && series?.period === 'custom'
      && (series?.groupingBy === 'week' || series?.groupingBy === 'month')
      && series.startDate
      && series.endDate
    ) {
      const startDate = new Date(series.startDate);
      const endDate = new Date(series.endDate);
      const isValidPeriod = series.groupingBy === 'week'
        ? endDate.getTime() - startDate.getTime() >= 6 * 24 * 60 * 60 * 1000
        : endDate.getTime() - startDate.getTime() >= 29 * 24 * 60 * 60 * 1000;
      setIsValidCustomPeriod(isValidPeriod);
    }
  }, [series?.period, series?.groupingBy, series?.startDate, series?.endDate]);

  return (
    <Row spacing={2}>
      <Column xs={12}>
        {/* Period */}
        <SelectField
          fullWidth
          label={`${t('stats.drawer.period', 'period')}`}
          value={series?.period}
          required
          disabled={addSeries && statsType !== 'summary'}
          onChange={(_) => {
            setCurrentValues({
              ...series,
              period: _.target.value,
              startDate: getStartDate(_.target.value),
              endDate: getEndDate(_.target.value),
            });
          }}
          options={[...periodHardcoded]}
        />
      </Column>
      <Column xs={6}>
        {/* date */}
        {series?.period !== 'custom' ? (
          // start date
          <TextField
            readonly
            required
            label={`${t('stats.drawer.startDate', 'start date')}`}
            value={series?.startDate
              ? (isNaN(Date.parse(series?.startDate))
                ? '-'
                : dateToUtc(new Date(series.startDate)))
              : '-'}
          />
        ) : (
          <DatePicker
            error={!isValidCustomPeriod}
            helperText={!isValidCustomPeriod ? customPeriodErrorText : undefined}
            required
            label={t('stats.drawer.startDate', 'start date')}
            date={series?.startDate}
            disabled={addSeries && statsType !== 'summary'}
            onChange={(date: Date) => {
              if (isDate(date)) {
                setCurrentValues({ ...series, startDate: dateToUtc(date) });
              }
            }}
          />
        )}
      </Column>
      <Column xs={6}>
        {series?.period !== 'custom' ? (
          // end date
          <TextField
            readonly
            required
            label={`${t('stats.drawer.endDate', 'end date')}`}
            value={series?.endDate
              ? (isNaN(Date.parse(series?.endDate))
                ? '-'
                : dateToUtc(new Date(series.endDate)))
              : '-'}
          />
        ) : (
          <DatePicker
            error={!isValidCustomPeriod}
            helperText={!isValidCustomPeriod ? customPeriodErrorText : undefined}
            label={t('stats.drawer.endDate', 'end date')}
            date={series?.endDate}
            disabled={addSeries && statsType !== 'summary'}
            onChange={(date: Date) => {
              if (isDate(date)) {
                setCurrentValues({ ...series, endDate: dateToUtc(date) });
              }
            }}
          />
        )}
      </Column>
      {statsType === 'trend' && (
        <Column xs={12}>
          <SelectField
            fullWidth
            label={t('stats.drawer.groupingBy', 'grouping by')}
            value={series?.groupingBy}
            required
            disabled={addSeries}
            onChange={(_) => setCurrentValues({ ...series, groupingBy: _.target.value })}
            options={[...groupByHardcoded]}
          />
        </Column>
      )}
      <Column xs={12}>
        <AthletesListSelector
          athletes={series?.athletes}
          athleteList={athleteOptions}
          addSeries={addSeries}
          allowAthleteMultivalue={allowAthleteMultivalue}
          statsType={statsType}
          defaultValueSelected={defaultValueSelected}
          setCurrentValues={setCurrentValues}
          startDate={series?.startDate}
          endDate={series?.endDate}
        />
      </Column>
      <Column xs={12}>
        {/* KPI */}
        {/* { statsType !== 'radar' ? */}
        <KpiListSelector
          kpis={series?.KPI}
          kpiOptions={kpiOptions}
          statsType={statsType}
          multiple={statsType == 'radar'}
          maxNumber={5}
          setCurrentValues={setCurrentValues}
          addSeries={addSeries}
        />
      </Column>
      <Column xs={12}>
        {/* categories */}
        <CategoryListSelector
          categories={series?.categories}
          categoryOptions={categoryOptions}
          required
          label={`${t('stats.drawer.categories', 'categories')}`}
          onSelect={(c) => {
            setCurrentValues({ ...series, categories: c });
          }}
        />
      </Column>
      <Column xs={12}>
        {/* tags */}
        <TagListSelector
          tags={series?.tags}
          tagOptions={tagOptions}
          multiple
          label={t('stats.drawer.tags', 'tags')}
          onSelect={(t) => {
            setCurrentValues({ ...series, tags: t });
          }}
        />
      </Column>
      <Column xs={12}>
        <MatchSelector
          fullWidth
          autocomplete
          label={t('calendar.labels.matchDistance', 'match distance')}
          value={series?.matchDistance || []}
          options={matchDistanceOptions}
          type="match distance"
          onSelect={(m) => {
            setCurrentValues({ ...series, matchDistance: m });
          }}
        />
      </Column>
      <Column xs={12}>
        <MatchSelector
          fullWidth
          autocomplete
          label={t('session.labels.matchCycle', 'match cycle')}
          value={series?.matchCycle || []}
          options={matchCycleOptions}
          type="match cycle"
          onSelect={(m) => {
            setCurrentValues({ ...series, matchCycle: m });
          }}
        />
      </Column>
      {barLine && (
        <Column xs={12}>
          <FieldsGroup
            label={t('stats.drawer.chartType')}
            type="radio"
            value={series?.chartType}
            name="radio-buttons-group"
            onChange={(v) => {
              setCurrentValues({
                ...series,
                chartType: v.target.value,
              });
            }}
            options={[
              {
                value: 'column',
                label: t('stats.drawer.bar', 'bar'),
              },
              {
                value: 'spline',
                label: t('stats.drawer.line', 'line'),
              },
            ]}
          />
        </Column>
      )}
    </Row>
  );
}

export default withMe(SeriesInputs);
