import {
  TrackAthleteSessionStateChoices,
  TrackAthleteSessionKpiStateChoices,
  GenericValueType,
  KPIType,
  LayoutType,
} from 'query/track';
import { AthleteType, getAthleteFragment, getAthleteFragmentName } from './athlete';
import {
  DrillType, getTeamSessionFragment, getTeamSessionFragmentName, TeamSessionsType,
} from './team_session';
import { getTrackFragment, getTrackFragmentName, TrackType } from './track';
import { IntervalType } from '../query/session';

export type MechType = {
  activeMuscleLoad?: GenericValueType,
  averageActiveMusclePower?: GenericValueType,
  externalWork?: GenericValueType,
  externalWorkNegative?: GenericValueType,
  averageExternalPower?: GenericBoundsType,
  eccentricIndex?: GenericBoundsType,
  brakeEvents?: GenericValueType,
  burstEvents?: GenericValueType,
  extWorkOver?: GenericValueType,
  extWorkOverNeg?: GenericValueType,
  extWorkOverZone0?: GenericValueType,
  extWorkOverZone0Neg?: GenericValueType,
  extWorkOverZone1?: GenericValueType,
  extWorkOverZone1Neg?: GenericValueType,
  extWorkOverZone2?: GenericValueType,
  extWorkOverZone2Neg?: GenericValueType,
}

type GenericBoundsType = {
  element: GenericValueType,
  lowerBound: GenericValueType,
  upperBound: GenericValueType,
}

export type AthleteSessionDrillType = {
  drill: number | null
  end: string
  id: string
  start: string
  totalTime?: GenericValueType
  intervals: IntervalType[] | null
}

export type DrillsType = {
  relatedDrills: DrillType[]
  wholeSession: DrillType
}

export type AthleteSessionType = {
  masterAthleteSession: string|number;
  accelerationEvents: {eventCount: GenericValueType},
  anaerobicEnergy: GenericValueType,
  athlete?: AthleteType,
  averageHdop: GenericValueType,
  averageHr?: GenericValueType,
  averageHrPercentual?: GenericValueType,
  averageP: GenericBoundsType,
  averagePowerAer: GenericValueType,
  averageSatprsum: GenericValueType,
  averageV: GenericValueType,
  createdOn?: Date,
  datetimeIntervals?: string,
  decelerationEvents: {eventCount: GenericValueType},
  distance?: GenericValueType,
  distRunning: GenericValueType,
  distWalking: GenericValueType,
  drill?: number,
  drills: DrillsType
  duration?: GenericValueType<number>,
  eccentricIndex?: GenericValueType,
  edwardsTl?: GenericValueType,
  endDt?: string,
  endDate?: string,
  energyRunning: GenericValueType,
  energyWalking: GenericValueType,
  equivalentDistance: GenericValueType,
  equivalentDistanceIndex?: GenericBoundsType,
  mech?: MechType,
  id: string,
  isConsolidate: boolean,
  isStatsValid: boolean,
  kpi?: KPIType[],
  identifierKpi?: KPIType[],
  KPIState: TrackAthleteSessionKpiStateChoices,
  layout: LayoutType[],
  locomotionActiveMuscleLoad?: GenericValueType,
  locomotionAverageActiveMusclePower?: GenericValueType,
  mpe?: GenericValueType,
  maxValuesAcc?: GenericValueType,
  maxValuesCardio?: GenericValueType,
  maxValuesDec?: GenericValueType,
  maxValuesPower?: GenericValueType,
  maxValuesSpeed?: GenericBoundsType,
  nature?: string,
  powerEventsAvgPower?: GenericValueType,
  powerEventsAvgTime?: GenericValueType,
  powerEventsCount: number,
  powerEventsTotalTime?: GenericValueType,
  recoveryAveragePower: GenericValueType,
  recoveryAverageTime: GenericValueType,
  rpeInfo?: {
    rpe: GenericValueType,
    lowerBound: GenericValueType,
    upperBound: GenericValueType,
  }
  sampleIntervals?: string,
  selfSampleIntervals: string,
  seriesList: string[],
  speedEvents: {eventCount: GenericValueType},
  state: TrackAthleteSessionStateChoices,
  startDt?: string,
  startDate?: string,
  startTime?: string,
  teamsession?: TeamSessionsType,
  timeRunning: GenericValueType,
  timeWalking: GenericValueType,
  totalDistance: GenericValueType,
  totalEnergy: GenericValueType,
  totalTime: GenericValueType,
  track?: TrackType,
  trackEventSlope: number,
  trackSampleCount: number,
  needReprocess?: boolean,
  updatedOn?: Date,
  starter: boolean,
  analysisModelVersion?: number,
  isGk?: boolean,
}
export const getOtherParamsFragment = () => `
  isStatsValid
  drill
  equivalentDistance {
    value
    unit
    uom
  }
  averageV {
    value
    unit
    uom
  }
  averageHr {
    value
    unit
    uom
  }
  averageP{
    element {
      value
      unit
      uom
    }
    lowerBound {
      value
      unit
      uom
    }
    upperBound {
      value
      unit
      uom
    }
  }
  averagePowerAer {
    value
    unit
    uom
  }
  totalEnergy {
    value
    unit
    uom
  }
  anaerobicEnergy {
    value
    unit
    uom
  }
  recoveryAverageTime {
    value
    unit
    uom
  }
  recoveryAveragePower {
    value
    unit
    uom
  }
  edwardsTl {
    value
    unit
    uom
  }
  timeRunning {
    value
    unit
    uom
  }
  timeWalking {
    value
    unit
    uom
  }
  distRunning {
    value
    unit
    uom
  }
  distWalking {
    value
    unit
    uom
  }
  energyRunning {
    value
    unit
    uom
  }
  energyWalking {
    value
    unit
    uom
  }
  totalTime {
    value
    unit
    uom
  }
  powerEventsAvgTime {
    value
    unit
    uom
  }
  powerEventsAvgPower {
    value
    unit
    uom
  }
  eccentricIndex {
    value
    unit
    uom
  }
  externalWork {
    value
    unit
    uom
  }
  averageExternalPower {
    value
    unit
    uom
  }
  maxValuesSpeed {
    element { unit, uom, value}
    upperBound { value }
    lowerBound { value }  
  }
  maxValuesAcc {
    value
    unit
    uom
  }
  maxValuesDec {
    value
    unit
    uom
  }
  maxValuesPower {
    value
    unit
    uom
  }
  maxValuesCardio {
    value
    unit
    uom
  }
  duration {
    value
    unit
    uom
  }
  distance {
    value
    unit
    uom
  }
  `;

export type AthleteSessionResponseType = 'with_track'|'with_athlete'|'with_team_session'|'with_other_params'
export const getAthleteSessionFragmentName = (types: AthleteSessionResponseType[]) => `AthleteSessionTypeFields${types.sort().map((t) => `_${t}`).join('')}`;

export const getAthleteSessionFragment = (types: AthleteSessionResponseType[]) =>
  // tslint:disable-next-line: prefer-template
  `
    ${types.includes('with_track') ? getTrackFragment(['with_device']) : ''}
    ${types.includes('with_athlete') ? getAthleteFragment([]) : ''}
    ${types.includes('with_team_session') ? getTeamSessionFragment([]) : ''}
    fragment ${getAthleteSessionFragmentName(types)} on AthleteSessionType {
      id
      createdOn
      updatedOn
      state
      KPIState${
  types.includes('with_track') ? `
      track {
        ...${getTrackFragmentName(['with_device'])}
      }` : ''
}${types.includes('with_athlete') ? `
      athlete {
        ...${getAthleteFragmentName([])}
      }` : ''
}${types.includes('with_team_session') ? `
      teamsession {
        ...${getTeamSessionFragmentName([])}
      }` : ''
}${types.includes('with_other_params') ? `...${getOtherParamsFragment()}` : ''
}
      kpi {
        name
        value
        unit
        uom
      }
    }
  `;
